<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import inboxMixin from 'shared/mixins/inboxMixin';
import SettingsSection from 'dashboard/components/SettingsSection.vue';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import BusinessDay from './BusinessDay.vue';
import EditOverrideDate from './OverrideDate/EditOverrideDate.vue';
import AddOverrideDate from './OverrideDate/AddOverrideDate.vue';
import {
  dayNames
} from '../helpers/businessHour';

export default {
  components: {
    SettingsSection,
    BusinessDay,
    WootMessageEditor,
    EmptyState,
    EditOverrideDate,
    AddOverrideDate
  },
  mixins: [inboxMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dayNames: [...dayNames],
      tableHeader: [
        this.$t('OVERRIDE_DATES_MGMT.TABLE_HEADER.DATE'),
        this.$t('OVERRIDE_DATES_MGMT.TABLE_HEADER.WEEKDAY'),
        this.$t('OVERRIDE_DATES_MGMT.TABLE_HEADER.TIME')
      ],
      overrideDates: [],
      showDeleteConfirmationPopup: false,
      showEditPopup: false,
      showAddPopup: false,
      selectedOverrideDate: {},
      loading: {}
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
    deleteMessage() {
      return ` ${this.parseDate(this.selectedOverrideDate.day, this.selectedOverrideDate.month, this.selectedOverrideDate.year)}?`
    }
  },
  watch: {
    inbox() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      const {
        override_dates: overrideDates
      } = this.inbox;
      this.overrideDates = overrideDates;
    },
    parseDate(day, month, year) {
      return new Date(year, month - 1, day).toLocaleDateString(this.$i18n.locale.replace('_', '-'));
    },
    parseWorkingHours(workingHours) {
      if (workingHours.length === 1) {
        const { open_hour, open_minutes, close_hour, close_minutes, open_all_day, closed_all_day } = workingHours[0];
        if (open_all_day) {
          return this.$t('OVERRIDE_DATES_MGMT.OPEN_ALL_DAY');
        } else if (closed_all_day) {
          return this.$t('OVERRIDE_DATES_MGMT.CLOSED_ALL_DAY');
        } else {
          return `${String(open_hour).padStart(2, '0')}:${String(open_minutes).padStart(2, '0')} - ${String(close_hour).padStart(2, '0')}:${String(close_minutes).padStart(2, '0')}`;
        }
      } else {
        return workingHours
          .slice()
          .sort((a, b) => a.open_hour - b.open_hour || a.open_minutes - b.open_minutes)
          .map(wh => {
            const { open_hour, open_minutes, close_hour, close_minutes } = wh;
            return `${String(open_hour).padStart(2, '0')}:${String(open_minutes).padStart(2, '0')} - ${String(close_hour).padStart(2, '0')}:${String(close_minutes).padStart(2, '0')}`;
          })
          .join(', ');
      }
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    hideAddPopup() {
      this.showAddPopup = false
    },
    confirmDeletion() {
      this.loading[this.selectedOverrideDate.id] = true;
      this.closeDeletePopup();
      this.deleteOverrideDate(this.selectedOverrideDate.id);
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedOverrideDate = response;
    },
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedOverrideDate = response;
    },
    openAddPopup() {
      this.showAddPopup = true;
      this.selectedOverrideDate = {};
    },

    async deleteOverrideDate (id) {
      try {
        await this.$store.dispatch('overrideDates/delete', id);
        useAlert(this.$t('OVERRIDE_DATES_MGMT.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        const errorMessage =
          error?.message || this.$t('OVERRIDE_DATES_MGMT.DELETE.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      } finally {
        this.loading[this.selectedOverrideDate.id] = false;
        this.$store.dispatch('inboxes/get', this.inbox.id);
      }
    }
  },
};
</script>

<template>
  <div class="mx-8">
    <SettingsSection
      :title="$t('OVERRIDE_DATES_MGMT.TITLE')"
      :sub-title="$t('OVERRIDE_DATES_MGMT.SUB_TITLE')"
    >
      <div class="flex justify-end">
        <woot-button
          class="button nice rounded-md"
          icon="add-circle"
          @click="openAddPopup"
        >
          {{ $t('OVERRIDE_DATES_MGMT.HEADER_BTN_TXT') }}
        </woot-button>
      </div>
      <table class="min-w-full overflow-x-auto divide-y divide-slate-75 dark:divide-slate-700">
        <thead>
        <th
            v-for="thHeader in tableHeader"
            :key="thHeader"
            class="py-4 ltr:pr-4 rtl:pl-4 text-left font-semibold text-slate-700 dark:text-slate-300"
        >
            {{ thHeader }}
        </th>
        </thead>
        <tbody class="divide-y divide-slate-25 dark:divide-slate-800 flex-1 text-slate-700 dark:text-slate-100">
          <template v-if="overrideDates.length != 0">
            <tr v-for="(overrideDate, index) in overrideDates" :key="index">
              <td>{{ parseDate(overrideDate.day, overrideDate.month, overrideDate.year) }}</td>
              <td>{{ $t(`INBOX_MGMT.BUSINESS_HOURS.DAY_NAMES.${dayNames[overrideDate.day_of_week]}`) }}</td>
              <td>{{ parseWorkingHours(overrideDate.working_hours) }}</td>
              <td class="button-wrapper !min-w-[0px]">
                <woot-button
                  v-tooltip.top="$t('OVERRIDE_DATES_MGMT.FORM.EDIT')"
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  class-names="grey-btn"
                  icon="edit"
                  @click="openEditPopup(overrideDate)"
                />
                <woot-button
                  v-tooltip.top="$t('OVERRIDE_DATES_MGMT.FORM.DELETE')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  :is-loading="loading[overrideDate.id]"
                  @click="openDeletePopup(overrideDate)"
                />
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="3" class="py-4 text-center text-slate-500 dark:text-slate-400">
              {{ $t('OVERRIDE_DATES_MGMT.NO_DATA') }}
            </td>
          </tr>
        </tbody>
      </table>
      <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup" :size="'w-[60rem]'">
        <EditOverrideDate :selected-response="selectedOverrideDate" :title="parseDate(selectedOverrideDate.day, selectedOverrideDate.month, selectedOverrideDate.year)" @close="hideEditPopup" />
      </woot-modal>
      <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup" :size="'w-[60rem]'">
        <AddOverrideDate :inbox_id="inbox.id" @close="hideAddPopup" />
      </woot-modal>
      <woot-delete-modal
        :show.sync="showDeleteConfirmationPopup"
        :on-close="closeDeletePopup"
        :on-confirm="confirmDeletion"
        :title="$t('OVERRIDE_DATES_MGMT.DELETE.CONFIRM.TITLE')"
        :message="$t('OVERRIDE_DATES_MGMT.DELETE.CONFIRM.MESSAGE')"
        :message-value="deleteMessage"
        :confirm-text="$t('OVERRIDE_DATES_MGMT.DELETE.CONFIRM.YES')"
        :reject-text="$t('OVERRIDE_DATES_MGMT.DELETE.CONFIRM.NO')"
      />
    </SettingsSection>
  </div>
</template>

<style lang="scss" scoped>
.timezone-input-wrap {
  &::v-deep .multiselect {
    @apply mt-2;
  }
}

::v-deep.message-editor {
  @apply border-0;
}

.unavailable-input-wrap {
  textarea {
    @apply min-h-[4rem] mt-2;
  }
}

::v-deep .ve-pagination {
  @apply bg-transparent dark:bg-transparent;
}

::v-deep .ve-pagination-select {
  @apply hidden;
}

.table-pagination {
  @apply mt-4 text-right;
}

td {
  @apply py-4;
}

.button-wrapper {
  @apply flex justify-center gap-2;
}
</style>
