<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import BarChart from 'shared/components/charts/BarChart.vue';
import fromUnixTime from 'date-fns/fromUnixTime';
import startOfDay from 'date-fns/startOfDay';

const props = defineProps({
  heatData: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();

const todayData = computed(() => {
  const today = startOfDay(new Date()).toISOString();
  return props.heatData.filter(data => {
    const date = fromUnixTime(data.timestamp);
    return startOfDay(date).toISOString() === today;
  });
});

const chartData = computed(() => {
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const labels = hours.map(hour => `${hour}h`);
  const values = hours.map(hour => {
    const dataPoint = todayData.value.find(data => {
      const date = fromUnixTime(data.timestamp);
      return date.getHours() === hour;
    });
    return dataPoint ? dataPoint.value : 0;
  });

  return {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: '#3B82F6', // Tailwind blue-500
        borderRadius: 4,
      },
    ],
  };
});

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 10,
      right: 10,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: context => {
          const value = context.raw;
          if (value === 1) {
            return t('OVERVIEW_REPORTS.CONVERSATION_HEATMAP.CONVERSATION', {
              count: value,
            });
          }
          return t('OVERVIEW_REPORTS.CONVERSATION_HEATMAP.CONVERSATIONS', {
            count: value,
          });
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 0,
        autoSkip: true,
        autoSkipPadding: 15,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
      grid: {
        color: 'rgba(0, 0, 0, 0.1)',
      },
    },
  },
};
</script>

<template>
  <div v-if="isLoading" class="flex items-center justify-center py-4 w-full">
    <span class="text-n-slate-11">
      {{ $t('OVERVIEW_REPORTS.CONVERSATION_HEATMAP.LOADING_MESSAGE') }}
    </span>
  </div>
  <div v-else class="h-72 w-full">
    <BarChart
      :collection="chartData"
      :chart-options="chartOptions"
      class="w-full h-full"
    />
  </div>
</template>