<script>
import { mapGetters } from 'vuex'
import { frontendURL } from 'dashboard/helper/URLHelper'
import { useRouter } from 'vue-router'

export default {
  name: 'BotOverviewMetrics',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter()
    return { router }
  },
  computed: {
    ...mapGetters({
      metrics: 'bot/getMetrics',
    }),
    totalConversations() {
      return this.metrics?.totalConversationsCount ?? 0
    },
    retentionRate() {
      return this.metrics?.retentionRate ?? 0
    },
    handoffRate() {
      return this.metrics?.handoffRate ?? 0
    },
    botCsatScore() {
      // CSAT for conversations without agents
      const botResponses = this.metrics?.botCsatResponses ?? 0
      return botResponses > 0 ? (this.metrics?.botSatisfactionScore ?? 0) : 0
    },
  },
  methods: {
    formatToPercent(value) {
      return value ? `${value}%` : '--'
    },
    goToBotReport() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      const startTimestamp = Math.floor(today.getTime() / 1000)
      const endTimestamp = Math.floor(today.getTime() / 1000) + (24 * 60 * 60 - 1)

      const url = frontendURL(`accounts/${this.router.currentRoute.value.params.accountId}/reports/bot`)
      this.router.push({
        path: url,
        query: {
          since: startTimestamp,
          until: endTimestamp,
        },
      })
    },
  },
}
</script>

<template>
  <div v-if="isLoading" class="flex items-center justify-center py-4 w-full">
    <span class="text-n-slate-11">
      {{ $t('BOT_REPORTS.LOADING') }}
    </span>
  </div>
  <div v-else class="flex flex-col gap-4 w-full">
    <div class="flex flex-wrap gap-4 w-full">
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('BOT_REPORTS.METRIC.TOTAL_CONVERSATIONS.LABEL') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">{{ totalConversations }}</p>
      </div>
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('BOT_REPORTS.METRIC.RETENTION_RATE.LABEL') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">
          {{ formatToPercent(retentionRate) }}
        </p>
      </div>
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('BOT_REPORTS.METRIC.HANDOFF_RATE.LABEL') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">
          {{ formatToPercent(handoffRate) }}
        </p>
      </div>
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('BOT_REPORTS.METRIC.CSAT_SCORE.LABEL') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">
          {{ formatToPercent(botCsatScore) }}
        </p>
      </div>
    </div>
  </div>
</template>