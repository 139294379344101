<script>
import { mapGetters } from 'vuex';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { useRouter } from 'vue-router';

export default {
  name: 'ConversationStatusMetrics',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  computed: {
    ...mapGetters({
      accountConversationMetric: 'getAccountConversationMetric',
      agentConversationMetric: 'getAgentConversationMetric',
    }),
    metrics() {
      const metric = {};
      if (this.accountConversationMetric) {
        // Basic metrics
        metric.open = this.accountConversationMetric.open || 0;
        metric.unattended = this.accountConversationMetric.unattended || 0;
        metric.unassigned = this.accountConversationMetric.unassigned || 0;
        metric.pending = this.accountConversationMetric.pending || 0;
        metric.snoozed = this.accountConversationMetric.snoozed || 0;
        metric.closed_today = this.accountConversationMetric.closed_today || 0;

        // Time metrics from inboxes
        const inboxes = this.accountConversationMetric.inboxes || [];
        let totalQueueTime = 0;
        let inboxCount = 0;

        inboxes.forEach(inbox => {
          // Only consider queue time if it's a valid number and greater than 0
          if (
            inbox.metrics &&
            typeof inbox.metrics.queueTime === 'number' &&
            !Number.isNaN(inbox.metrics.queueTime) &&
            inbox.metrics.queueTime > 0
          ) {
            totalQueueTime += inbox.metrics.queueTime;
            inboxCount += 1;
          }
        });

        metric.queueTime = inboxCount > 0
          ? Math.round(totalQueueTime / inboxCount)
          : 0;

        // Resolution time from agent metrics
        let totalResolutionTime = 0;
        let agentsWithResolutionTime = 0;

        (this.agentConversationMetric || []).forEach(agent => {
          if (agent.avg_resolution_time) {
            totalResolutionTime += agent.avg_resolution_time;
            agentsWithResolutionTime += 1;
          }
        });

        metric.resolutionTime = agentsWithResolutionTime > 0
          ? Math.round(totalResolutionTime / agentsWithResolutionTime)
          : 0;
      }
      return metric;
    },
    formattedQueueTime() {
      const time = this.metrics.queueTime;
      if (!time) return '--';
      // Convert seconds to minutes and hours
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      
      if (hours > 0) {
        return `${hours}h ${minutes}m`;
      }
      return `${minutes}m`;
    },
    formattedResolutionTime() {
      const time = this.metrics.resolutionTime;
      if (!time) return '--';
      // Convert seconds to hours and minutes
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      
      if (hours > 0) {
        return `${hours}h ${minutes}m`;
      }
      return `${minutes}m`;
    },
  },
  methods: {
    goToConversationsReport() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const startTimestamp = Math.floor(today.getTime() / 1000);
      const endTimestamp = Math.floor(today.getTime() / 1000) + (24 * 60 * 60 - 1);

      const url = frontendURL(
        `accounts/${this.router.currentRoute.value.params.accountId}/reports/conversations`
      );
      this.router.push({
        path: url,
        query: {
          since: startTimestamp,
          until: endTimestamp,
        },
      });
    },
  },
};
</script>

<template>
  <div v-if="isLoading" class="flex items-center justify-center py-4 w-full">
    <span class="text-n-slate-11">
      {{ $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.LOADING_MESSAGE') }}
    </span>
  </div>
  <div v-else class="flex flex-col gap-4 w-full">
    <div class="flex flex-wrap gap-4 w-full">
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.OPEN') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">{{ metrics.open }}</p>
      </div>
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.UNATTENDED') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">{{ metrics.unattended }}</p>
      </div>
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.UNASSIGNED') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">{{ metrics.unassigned }}</p>
      </div>
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.PENDING') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">{{ metrics.pending }}</p>
      </div>
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.SNOOZED') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">{{ metrics.snoozed }}</p>
      </div>
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.CLOSED_TODAY') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">{{ metrics.closed_today }}</p>
      </div>
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('REPORT.METRICS.QUEUE_TIME.NAME') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">{{ formattedQueueTime }}</p>
      </div>
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('REPORT.METRICS.RESOLUTION_TIME.NAME') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">{{ formattedResolutionTime }}</p>
      </div>
    </div>
    <div class="flex justify-end">
      <button
        class="text-woot text-sm hover:underline"
        @click="goToConversationsReport"
      >
        {{ $t('OVERVIEW_REPORTS.VIEW_DETAILS') }}
      </button>
    </div>
  </div>
</template>