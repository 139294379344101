<script>
import { mapGetters } from 'vuex';
import { useReportMetrics } from 'dashboard/composables/useReportMetrics';
import { GROUP_BY_FILTER, METRIC_CHART } from './constants';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import { formatTime } from '@chatwoot/utils';
import ChartStats from './components/ChartElements/ChartStats.vue';
import BarChart from 'shared/components/charts/BarChart.vue';

export default {
  components: { ChartStats, BarChart },
  props: {
    groupBy: {
      type: Object,
      default: () => ({}),
    },
    accountSummaryKey: {
      type: String,
      default: 'getAccountSummary',
    },
    reportKeys: {
      type: Object,
      default: () => ({
        CONVERSATIONS: 'conversations_count',
        INCOMING_MESSAGES: 'incoming_messages_count',
        OUTGOING_MESSAGES: 'outgoing_messages_count',
        FIRST_RESPONSE_TIME: 'avg_first_response_time',
        RESOLUTION_TIME: 'avg_resolution_time',
        RESOLUTION_COUNT: 'resolutions_count',
        REPLY_TIME: 'reply_time',
        QUEUE_TIME: 'queue_time',
        OPERATION_TIME: 'operation_time'
      }),
    },
  },
  setup(props) {
    const { calculateTrend, isAverageMetricType } = useReportMetrics(
      props.accountSummaryKey
    );
    return { calculateTrend, isAverageMetricType };
  },
  computed: {
    ...mapGetters({
      accountReport: 'getAccountReports',
    }),
    metrics() {
      const reportKeys = Object.keys(this.reportKeys)
      const infoText = {
        CONVERSATIONS: this.$t('REPORT.METRICS.CONVERSATIONS.INFO_TEXT', {
          default: 'Total number of conversations created in the selected time period.'
        }),
        INCOMING_MESSAGES: this.$t('REPORT.METRICS.INCOMING_MESSAGES.INFO_TEXT', {
          default: 'Total number of messages received from customers in the selected time period.'
        }),
        OUTGOING_MESSAGES: this.$t('REPORT.METRICS.OUTGOING_MESSAGES.INFO_TEXT', {
          default: 'Total number of messages sent by agents or automation in the selected time period.'
        }),
        FIRST_RESPONSE_TIME: this.$t('REPORT.METRICS.FIRST_RESPONSE_TIME.INFO_TEXT', {
          default: 'Average time taken for the first response after a conversation is created. Calculated as (Sum of first response times) / (Total number of conversations with responses).'
        }),
        RESOLUTION_TIME: this.$t('REPORT.METRICS.RESOLUTION_TIME.INFO_TEXT', {
          default: 'Average time taken to resolve conversations. Calculated as (Sum of resolution times) / (Total number of resolved conversations).'
        }),
        RESOLUTION_COUNT: this.$t('REPORT.METRICS.RESOLUTION_COUNT.INFO_TEXT', {
          default: 'Total number of conversations marked as resolved in the selected time period.'
        }),
        REPLY_TIME: this.$t('REPORT.METRICS.REPLY_TIME.INFO_TEXT', {
          default: 'Average time taken to reply to customer messages. Calculated as (Sum of all agent response times) / (Total number of agent responses).'
        }),
        QUEUE_TIME: this.$t('REPORT.METRICS.QUEUE_TIME.INFO_TEXT', {
          default: 'Average time conversations spend in unassigned state. Calculated as (Sum of assignment times - creation times) / (Total number of assigned conversations).'
        }),
        OPERATION_TIME: this.$t('REPORT.METRICS.OPERATION_TIME.INFO_TEXT', {
          default: 'Average time taken to resolve conversations after first agent assignment. Calculated as (Sum of resolution times - first assignment times) / (Total number of resolved conversations).'
        })
      }
      return reportKeys.map(key => ({
        NAME: this.$t(`REPORT.METRICS.${key}.NAME`),
        KEY: this.reportKeys[key],
        DESC: this.$t(`REPORT.METRICS.${key}.DESC`),
        INFO_TEXT: infoText[key],
        TOOLTIP_TEXT: `REPORT.METRICS.${key}.TOOLTIP_TEXT`,
        trend: this.calculateTrend(this.reportKeys[key])
      }))
    },
  },
  methods: {
    getCollection(metric) {
      if (!this.accountReport.data[metric.KEY]) {
        return {};
      }
      const data = this.accountReport.data[metric.KEY];
      const labels = data.map(element => {
        if (this.groupBy?.period === GROUP_BY_FILTER[2].period) {
          let week_date = new Date(fromUnixTime(element.timestamp));
          const first_day = week_date.getDate() - week_date.getDay();
          const last_day = first_day + 6;
          const week_first_date = new Date(week_date.setDate(first_day));
          const week_last_date = new Date(week_date.setDate(last_day));
          return `${format(week_first_date, 'dd-MMM')} - ${format(
            week_last_date,
            'dd-MMM'
          )}`;
        }
        if (this.groupBy?.period === GROUP_BY_FILTER[3].period) {
          return format(fromUnixTime(element.timestamp), 'MMM-yyyy');
        }
        if (this.groupBy?.period === GROUP_BY_FILTER[4].period) {
          return format(fromUnixTime(element.timestamp), 'yyyy');
        }
        return format(fromUnixTime(element.timestamp), 'dd-MMM');
      });
      const datasets = METRIC_CHART[metric.KEY].datasets.map(dataset => {
        switch (dataset.type) {
          case 'bar':
            return {
              ...dataset,
              yAxisID: 'y',
              label: metric.NAME,
              data: data.map(element => element.value),
            };
          case 'line':
            return {
              ...dataset,
              yAxisID: 'y',
              label: this.metrics[0].NAME,
              data: data.map(element => element.count),
            };
          default:
            return dataset;
        }
      });
      return {
        labels,
        datasets,
      };
    },
    getChartOptions(metric) {
      let tooltips = {};
      if (this.isAverageMetricType(metric.KEY)) {
        tooltips.callbacks = {
          label: tooltipItem => {
            return this.$t(metric.TOOLTIP_TEXT, {
              metricValue: formatTime(tooltipItem.yLabel),
              conversationCount:
                this.accountReport.data[metric.KEY][tooltipItem.index].count,
            });
          },
        };
      }
      return {
        scales: METRIC_CHART[metric.KEY].scales,
        tooltips: tooltips,
      };
    },
  },
};
</script>

<template>
  <div
    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 px-6 py-5 shadow outline-1 outline outline-n-container rounded-xl bg-n-solid-2"
  >
    <div
      v-for="metric in metrics"
      :key="metric.KEY"
      class="p-4 mb-3 rounded-md"
    >
      <ChartStats :metric="metric" :account-summary-key="accountSummaryKey" />
      <div class="mt-4 h-72">
        <woot-loading-state
          v-if="accountReport.isFetching[metric.KEY]"
          class="text-xs"
          :message="$t('REPORT.LOADING_CHART')"
        />
        <div v-else class="flex items-center justify-center h-72">
          <BarChart
            v-if="accountReport.data[metric.KEY].length"
            :collection="getCollection(metric)"
            :chart-options="getChartOptions(metric)"
          />
          <span v-else class="text-sm text-slate-600">
            {{ $t('REPORT.NO_ENOUGH_DATA') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
