<template>
  <label class="input-container">
    <span v-if="label">{{ label }}</span>
    <codemirror
      v-model="code"
      :placeholder="placeholder"
      :style="{ height: '300px', width: '100%' }"
      :autofocus="true"
      :indent-with-tab="true"
      :tab-size="2"
      :extensions="extensions"
      @ready="handleReady"
      @change="onCmCodeChange"
    />
    <p v-if="helpText" class="help-text">{{ helpText }}</p>
  </label>
</template>

<script>
  import { Codemirror } from 'vue-codemirror'
  import { javascript } from '@codemirror/lang-javascript'
  import { oneDark } from '@codemirror/theme-one-dark'
  import { defineComponent, ref, shallowRef } from 'vue'

  export default defineComponent({
    components: {
      Codemirror
    },
    props: {
      label: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        required: true,
      },
      helpText: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
    },
    setup(props, { emit }) {
      const code = ref(props.value)
      const extensions = [javascript(), oneDark]

      const view = shallowRef()
      const handleReady = (payload) => {
        view.value = payload.view
      }

      const onCmCodeChange = (newCode) => {
        emit('code-change', newCode)
      }

      const getCodemirrorStates = () => {
        const state = view.value.state
        const ranges = state.selection.ranges
        const selected = ranges.reduce((r, range) => r + range.to - range.from, 0)
        const cursor = ranges[0].anchor
        const length = state.doc.length
        const lines = state.doc.lines
      }

      return {
        code,
        extensions,
        handleReady,
        onCmCodeChange
      }
    }
  })
</script>
<style scoped lang="scss">
.help-text {
  @apply mt-0.5 text-xs not-italic text-slate-600 dark:text-slate-400;
}
</style>