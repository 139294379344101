<script>
import parse from 'date-fns/parse';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { generateTimeSlots, hasTimeSlotOverlap } from '../helpers/businessHour';

const timeSlots = generateTimeSlots(30);

export default {
  components: {},
  props: {
    day: {
      type: String,
      required: true
    },
    dayName: {
      type: String,
      required: true,
    },
    slots: {
      type: Array,
      default: () => [
        {
          from: '',
          to: '',
          openAllDay: false,
          valid: false
        },
      ],
    },
  },
  emits: ['update'],
  computed: {
    fromTimeSlots() {
      return timeSlots;
    },
    toTimeSlots() {
      return timeSlots.filter(slot => slot !== '12:00 AM');
    },
    isDayEnabled() {
      return this.slots.some(slot => slot.from && slot.to);
    },
    totalHours() {
      if (this.openAllDay) {
        return 24;
      }
      return this.slots.reduce((total, slot) => {
        const fromDate = parse(slot.from, 'hh:mm a', new Date());
        const toDate = parse(slot.to, 'hh:mm a', new Date());
        return total + differenceInMinutes(toDate, fromDate) / 60;
      }, 0);
    },
    openAllDay() {
      return this.slots[0]?.openAllDay === true;
    },
  },
  methods: {
    isNotValid(slot) {
      return !slot.valid
    },
    hasOverlap(index) {
      const targetSlot = this.slots[index];
      return hasTimeSlotOverlap(this.slots.filter((_, i) => i !== index), targetSlot);
    },
    updateSlot(index, newSlot) {
      const updatedSlots = [...this.slots];
      updatedSlots[index] = newSlot;
      this.$emit('update', updatedSlots);
    },
    handleFromTimeChange(index, value) {
      const fromDate = parse(value, 'hh:mm a', new Date());
      const toDate = parse(this.slots[index].to, 'hh:mm a', new Date());
      const valid = differenceInMinutes(toDate, fromDate) / 60 > 0;
      this.updateSlot(index, {
        ...this.slots[index],
        from: value,
        valid: valid,
      });
    },
    handleToTimeChange(index, value) {
      const toDate = parse(value, 'hh:mm a', new Date());
      const fromDate = parse(this.slots[index].from, 'hh:mm a', new Date());
      const valid = differenceInMinutes(toDate, fromDate) / 60 > 0;
      this.updateSlot(index, {
        ...this.slots[index],
        to: value,
        valid: valid,
      });
    },
    addTimeSlot() {
      this.$emit('add-time-slot');
    },
    removeTimeSlot(index) {
      this.$emit('update', this.slots.filter((_, i) => i !== index));
    },
    handleOpenAllDayChange(value) {
      this.$emit('open-all-day', value)
    },
    handleEnableDayChange(value) {
      this.$emit('enable-day', value)
    },
  }
};
</script>

<template>
  <div class="day-wrap">
    <div class="day">
      <div class="enable-day">
        <div class="checkbox-wrap">
          <input
          name="enable-day"
          class="enable-checkbox"
          type="checkbox"
          :title="$t('INBOX_MGMT.BUSINESS_HOURS.DAY.ENABLE')"
          :checked="isDayEnabled"
          @change="handleEnableDayChange($event.target.checked)"
          />
        </div>
        <span>{{ dayName }}</span>
      </div>
      <div class="open-all-day">
        <div class="checkbox-wrap">
          <input
            :checked="openAllDay"
            name="enable-open-all-day"
            class="enable-checkbox text-sm font-medium"
            type="checkbox"
            :title="$t('INBOX_MGMT.BUSINESS_HOURS.ALL_DAY')"
            @change="handleOpenAllDayChange($event.target.checked)"
          />
          <span class="text-sm font-medium ml-1">{{
            $t('INBOX_MGMT.BUSINESS_HOURS.ALL_DAY')
          }}</span>
        </div>
      </div>
    </div>
    <div v-if="isDayEnabled" class="hours-select-wrap">
      <div
        v-for="(slot, index) in slots"
        :key="index"
        class="hours-range"
      >
        <multiselect
          v-model="slot.from"
          :options="fromTimeSlots"
          deselect-label=""
          select-label=""
          selected-label=""
          :placeholder="$t('INBOX_MGMT.BUSINESS_HOURS.DAY.CHOOSE')"
          :allow-empty="false"
          :disabled="openAllDay"
          @input="handleFromTimeChange(index, slot.from)"
        />
        <div class="separator-icon flex items-center py-0 px-3">
          <fluent-icon icon="subtract" type="solid" size="16" />
        </div>
        <multiselect
          v-model="slot.to"
          :options="toTimeSlots"
          deselect-label=""
          select-label=""
          selected-label=""
          :placeholder="$t('INBOX_MGMT.BUSINESS_HOURS.DAY.CHOOSE')"
          :allow-empty="false"
          :disabled="openAllDay"
          @input="handleToTimeChange(index, slot.to)"
        />
        <div class="icon-buttons-wrap">
          <button
            v-if="index === 0 && !openAllDay"
            @click.prevent="addTimeSlot()"
            :title="$t('INBOX_MGMT.BUSINESS_HOURS.ADD_TIME_SLOT')"
            class="add-time-slot-button"
          >
            <fluent-icon icon="add" type="solid" size="16" />
          </button>
          <button
            v-if="slots.length > 1"
            @click.prevent="removeTimeSlot(index)"
            :title="$t('INBOX_MGMT.BUSINESS_HOURS.REMOVE_TIME_SLOT')"
            class="remove-time-slot-button"
          >
            <fluent-icon icon="delete" type="outline" size="16" />
          </button>
        </div>
        <div v-if="isNotValid(slot)" class="date-error">
          <span class="error">{{
            $t('INBOX_MGMT.BUSINESS_HOURS.DAY.VALIDATION_ERROR')
          }}</span>
        </div>
        <div v-if="hasOverlap(index)" class="date-error">
          <span class="error">{{
            $t('INBOX_MGMT.BUSINESS_HOURS.DAY.OVERLAP_ERROR')
          }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="unavailable-message"
    >
      <span>
        {{ $t('INBOX_MGMT.BUSINESS_HOURS.DAY.UNAVAILABLE') }}
      </span>
    </div>
    <div>
      <span
        v-if="isDayEnabled && !hasError"
        class="label bg-woot-50 dark:bg-woot-600 text-woot-700 dark:text-woot-100 text-xs inline-block px-2 py-1 rounded-sm cursor-default whitespace-nowrap"
      >
        {{ totalHours }} {{ $t('INBOX_MGMT.BUSINESS_HOURS.DAY.HOURS') }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.day-wrap::v-deep .multiselect {
  @apply m-0 w-[7.5rem];

  > .multiselect__tags {
    @apply pl-3;

    .multiselect__single {
      @apply text-sm leading-6 py-2 px-0;
    }
  }
}
.day-wrap {
  @apply flex flex-wrap items-start gap-8 justify-between py-2 px-0 min-h-[3rem] box-content border-b border-solid border-slate-50 dark:border-slate-600;
}

.enable-checkbox {
  @apply m-0;
}

.hours-select-wrap {
  @apply flex flex-col flex-shrink-0 flex-grow gap-2 relative;
}

.hours-range,
.day-unavailable {
  @apply flex items-center flex-shrink-0 flex-grow;
}

.day-unavailable {
  @apply mt-3 text-sm text-slate-500 dark:text-slate-300;
}

.unavailable-message {
  @apply flex items-center flex-shrink-0 flex-grow text-sm text-slate-500 dark:text-slate-300 mt-3;
}

.enable-day,
.open-all-day {
  @apply flex items-center gap-2 w-[9em];
}

.separator-icon {
  @apply flex items-center py-0 px-3;
}

.day {
  @apply flex text-sm gap-2 font-medium mt-3;
}

.label {
  @apply bg-woot-50 dark:bg-woot-600 text-woot-700 dark:text-woot-100 text-xs inline-block px-2 py-1 rounded-sm cursor-default whitespace-nowrap;
}

.date-error {
  @apply pt-1;
}

.error {
  @apply text-xs text-red-300 dark:text-red-500;
}

.open-all-day {
  span {
    @apply text-sm font-medium ml-1;
  }
  input {
    @apply text-sm font-medium;
  }
}
</style>