<template>
	<woot-reports
		key="classification-reports"
		type="classification"
		getter-key="classifications/getChildrenClassifications"
		action-key="classifications/get"
		:download-button-label="$t('CLASSIFICATION_REPORTS.DOWNLOAD_CLASSIFICATION_REPORTS')"
		:report-title="$t('CLASSIFICATION_REPORTS.HEADER')"
	/>
</template>

<script>
import WootReports from './components/WootReports.vue';

export default {
	components: {
		WootReports,
	},
};
</script>