<script>
import { mapGetters } from 'vuex';
import { OVERVIEW_METRICS } from '../../constants';

export default {
  name: 'AgentStatusMetrics',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      agentStatus: 'agents/getAgentStatus',
    }),
    metrics() {
      let metric = {};
      Object.keys(this.agentStatus).forEach(key => {
        const metricName = this.$t(
          `OVERVIEW_REPORTS.AGENT_STATUS.${OVERVIEW_METRICS[key]}`
        );
        metric[metricName] = this.agentStatus[key];
      });
      return metric;
    },
  },
};
</script>

<template>
  <div v-if="isLoading" class="flex items-center justify-center py-4 w-full">
    <span class="text-n-slate-11">
      {{ $t('OVERVIEW_REPORTS.AGENT_STATUS.LOADING_MESSAGE') }}
    </span>
  </div>
  <div v-else class="flex flex-row items-center gap-4 w-full">
    <div
      v-for="(metric, name, index) in metrics"
      :key="index"
      class="flex-1 bg-n-slate-2 rounded-lg p-4"
    >
      <h3 class="text-sm text-n-slate-11 truncate">
        {{ name }}
      </h3>
      <p class="text-2xl text-n-slate-12 mt-1">
        {{ metric }}
      </p>
    </div>
  </div>
</template>