<script setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import Button from 'dashboard/components-next/button/Button.vue';

const props = defineProps({
  contacts: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['back', 'confirm']);

const { t } = useI18n();

// Compute statistics about the contacts
const statistics = computed(() => {
  const totalCount = props.contacts.length;
  
  // Count contacts with and without names
  const withName = props.contacts.filter(contact => contact.name).length;
  const withoutName = totalCount - withName;
  
  // Count contacts with labels if available
  const withLabels = props.contacts.filter(
    contact => contact.labels && contact.labels.length > 0
  ).length;
  
  return {
    totalCount,
    withName,
    withoutName,
    withLabels,
    percentWithName: totalCount > 0 
      ? Math.round((withName / totalCount) * 100) 
      : 0,
  };
});

const handleBack = () => {
  emit('back');
};

const handleConfirm = () => {
  emit('confirm');
};
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <h4 class="text-sm font-medium text-slate-900 dark:text-slate-50">
        {{ t('CAMPAIGN.WHATSAPP.PREVIEW.TITLE') }}
      </h4>
      <p class="text-xs text-slate-600 dark:text-slate-400">
        {{ 
          t('CAMPAIGN.WHATSAPP.PREVIEW.DESCRIPTION', { count: contacts.length }) 
        }}
      </p>
    </div>

    <!-- Statistics Summary -->
    <div 
      v-if="contacts.length > 0"
      class="bg-slate-50 dark:bg-slate-800 p-3 rounded-md"
    >
      <h5 class="text-xs font-medium text-slate-700 dark:text-slate-300 mb-2">
        {{ t('CAMPAIGN.WHATSAPP.PREVIEW.STATISTICS.TITLE') }}
      </h5>
      <div class="grid grid-cols-2 gap-2 text-xs">
        <div class="flex flex-col">
          <span class="text-slate-500 dark:text-slate-400">
            {{ t('CAMPAIGN.WHATSAPP.PREVIEW.STATISTICS.TOTAL_CONTACTS') }}:
          </span>
          <span class="font-medium text-slate-900 dark:text-slate-50">{{ statistics.totalCount }}</span>
        </div>
        <div class="flex flex-col">
          <span class="text-slate-500 dark:text-slate-400">
            {{ t('CAMPAIGN.WHATSAPP.PREVIEW.STATISTICS.WITH_NAME') }}:
          </span>
          <span class="font-medium text-slate-900 dark:text-slate-50">
            {{ statistics.withName }} ({{ statistics.percentWithName }}%)
          </span>
        </div>
        <div v-if="statistics.withLabels > 0" class="flex flex-col">
          <span class="text-slate-500 dark:text-slate-400">
            {{ t('CAMPAIGN.WHATSAPP.PREVIEW.STATISTICS.WITH_LABELS') }}:
          </span>
          <span class="font-medium text-slate-900 dark:text-slate-50">{{ statistics.withLabels }}</span>
        </div>
      </div>
    </div>

    <div class="max-h-[300px] overflow-y-auto border border-slate-200 dark:border-slate-700 rounded-md">
      <table class="w-full text-sm">
        <thead class="bg-slate-50 dark:bg-slate-800 sticky top-0">
          <tr>
            <th class="p-2 text-left text-xs font-medium text-slate-500 dark:text-slate-400">
              {{ t('CAMPAIGN.WHATSAPP.PREVIEW.TABLE.NAME') }}
            </th>
            <th class="p-2 text-left text-xs font-medium text-slate-500 dark:text-slate-400">
              {{ t('CAMPAIGN.WHATSAPP.PREVIEW.TABLE.PHONE') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(contact, index) in contacts" 
            :key="index"
            class="border-t border-slate-200 dark:border-slate-700"
          >
            <td class="p-2 text-slate-900 dark:text-slate-50">
              {{ contact.name || t('CAMPAIGN.WHATSAPP.PREVIEW.TABLE.NO_NAME') }}
            </td>
            <td class="p-2 text-slate-900 dark:text-slate-50">
              {{ contact.phone_number }}
            </td>
          </tr>
          <tr v-if="contacts.length === 0" class="border-t border-slate-200 dark:border-slate-700">
            <td colspan="2" class="p-4 text-center text-slate-500 dark:text-slate-400">
              {{ t('CAMPAIGN.WHATSAPP.PREVIEW.TABLE.NO_CONTACTS') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex justify-between mt-2">
      <Button
        color="slate"
        variant="ghost"
        size="sm"
        :label="t('CAMPAIGN.WHATSAPP.PREVIEW.BACK')"
        @click="handleBack"
      />
      <Button
        color="blue"
        variant="solid"
        size="sm"
        :label="t('CAMPAIGN.WHATSAPP.PREVIEW.CONFIRM')"
        :disabled="contacts.length === 0"
        @click="handleConfirm"
      />
    </div>
  </div>
</template> 