<script>
import { OVERVIEW_METRICS } from '../../constants'
import Spinner from 'shared/components/Spinner.vue'

export default {
  name: 'InboxMetrics',
  components: {
    Spinner,
  },
  props: {
    inboxMetrics: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasInboxes() {
      return this.inboxMetrics && this.inboxMetrics.length > 0
    },
    metricKeys() {
      return ['open', 'unattended', 'unassigned', 'pending', 'snoozed', 'queueTime']
    },
  },
  methods: {
    getMetricName(key) {
      if (key === 'queueTime') {
        return this.$t('REPORT.METRICS.QUEUE_TIME.NAME')
      }
      return this.$t(`OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.${OVERVIEW_METRICS[key]}`)
    },
    formatQueueTime(time) {
      if (!time) return '--'
      // Convert seconds to minutes and round to 2 decimal places
      return `${(time / 60).toFixed(2)}m`
    },
  },
}
</script>

<template>
  <div v-if="isLoading" class="flex items-center justify-center py-4">
    <Spinner />
    <span class="ml-2 text-n-slate-11">
      {{ $t('OVERVIEW_REPORTS.INBOX_STATUS.LOADING_MESSAGE') }}
    </span>
  </div>
  <div v-else-if="hasInboxes" class="w-full overflow-x-auto">
    <table class="w-full min-w-[600px] border-collapse">
      <thead>
        <tr class="border-b border-n-slate-3">
          <th class="py-2 px-4 text-left text-sm text-n-slate-11">
            {{ $t('OVERVIEW_REPORTS.INBOX_STATUS.INBOX') }}
          </th>
          <th
            v-for="key in metricKeys"
            :key="key"
            class="py-2 px-4 text-left text-sm text-n-slate-11"
          >
            {{ getMetricName(key) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="inbox in inboxMetrics"
          :key="inbox.id"
          class="border-b border-n-slate-3 hover:bg-n-slate-1"
        >
          <td class="py-2 px-4 text-sm text-n-slate-12 font-medium">
            {{ inbox.name }}
          </td>
          <td
            v-for="key in metricKeys"
            :key="key"
            class="py-2 px-4 text-sm text-n-slate-12"
          >
            <template v-if="key === 'queueTime'">
              {{ formatQueueTime(inbox.metrics[key]) }}
            </template>
            <template v-else>
              {{ inbox.metrics[key] }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else class="text-center text-n-slate-11 py-4">
    {{ $t('OVERVIEW_REPORTS.INBOX_STATUS.NO_INBOXES') }}
  </div>
</template>