<script>
import { getUnixStartOfDay, getUnixEndOfDay } from 'helpers/DateHelper';
import subDays from 'date-fns/subDays';
import MetricCard from './components/overview/MetricCard.vue';
import ClassificationConversationTable from './components/overview/ClassificationConversationTable.vue';
import { mapGetters } from 'vuex';
import { emitter } from 'shared/helpers/mitt';
import WootButton from 'dashboard/components/ui/WootButton.vue';
import ReportsAPI from 'dashboard/api/reports';
import mutationTypes from 'dashboard/store/mutation-types';

export default {
  name: 'ClassificationConversationReports',
  components: {
    ClassificationConversationTable,
    MetricCard,
    WootButton,
  },
  data() {
    const today = new Date();
    const sevenDaysAgo = subDays(today, 7);
    return {
      customDateRange: [sevenDaysAgo, today],
    };
  },
  computed: {
    ...mapGetters({
      classificationMetrics: 'getClassificationConversationMetric',
      uiFlags: 'getOverviewUIFlags',
    }),
    from() {
      return getUnixStartOfDay(this.customDateRange[0]);
    },
    to() {
      return getUnixEndOfDay(this.customDateRange[1]);
    },
  },
  mounted() {
    this.fetchData();
    emitter.on('fetch_classification_conversation_reports', () => {
      this.fetchData();
    });
  },
  methods: {
    fetchData() {
      this.fetchClassificationConversationMetric();
    },
    async fetchClassificationConversationMetric() {
      try {
        const response = await ReportsAPI.getConversations({
          type: 'classification_conversation',
          since: this.from,
          until: this.to,
          timezone_offset: -(new Date().getTimezoneOffset() / 60),
        });
        this.$store.commit(
          mutationTypes.SET_CLASSIFICATION_CONVERSATION_METRICS,
          response.data
        );
      } catch (error) {
        console.error(error);
      }
    },
    onDateRangeChange(value) {
      this.customDateRange = value;
      this.fetchData();
    },
    async downloadReports() {
      try {
        await this.$store.dispatch('downloadClassificationReports', {
          from: this.from,
          to: this.to,
          fileName: `classification-report-${new Date().getTime()}.csv`,
          timezone_offset: -(new Date().getTimezoneOffset() / 60),
          format: 'csv'
        });
      } catch (error) {
        // Handle error
        console.error('Error downloading report:', error);
      }
    },
    async downloadClassificationReport(classificationId) {
      try {
        await this.$store.dispatch('downloadClassificationConversationsReports', {
          from: this.from,
          to: this.to,
          fileName: `classification-conversations-${classificationId}-${new Date().getTime()}.csv`,
          timezone_offset: -(new Date().getTimezoneOffset() / 60),
          classification_id: classificationId
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<template>
  <div class="flex-1 overflow-auto p-4">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-4 bg-n-solid-2 p-4 rounded-xl">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <woot-date-picker @date-range-changed="onDateRangeChange" />
          </div>
          <WootButton
            variant="secondary"
            size="small"
            @click="downloadReports"
          >
            {{ $t('REPORT.DOWNLOAD') }}
          </WootButton>
        </div>
        <div class="w-full flex flex-wrap flex-row ml-auto mr-auto">
          <MetricCard :header="$t('CLASSIFICATION_CONVERSATION_REPORTS.HEADER')">
            <ClassificationConversationTable
              :classification-metrics="classificationMetrics"
              :is-loading="uiFlags.isFetchingClassificationConversationMetric"
              @download-report="downloadClassificationReport"
            />
          </MetricCard>
        </div>
      </div>
    </div>
  </div>
</template>