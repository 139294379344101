<script setup>
import ReportMetricCard from './ReportMetricCard.vue';

const props = defineProps({
  conversationCount: {
    type: String,
    required: true,
  },
  retainedOrAssignedConversationsCount: {
    type: String,
    required: true,
  },
  messageCount: {
    type: String,
    required: true,
  },
  retentionRate: {
    type: String,
    required: true,
  },
  handoffRate: {
    type: String,
    required: true,
  },
});

const formatToPercent = value => {
  return value ? `${value}%` : '--';
};

</script>

<template>
  <div
    class="flex flex-wrap mx-0 shadow outline-1 outline outline-n-container rounded-xl bg-n-solid-2 px-6 py-5"
  >
    <ReportMetricCard
      :label="$t('BOT_REPORTS.METRIC.TOTAL_CONVERSATIONS.LABEL')"
      :info-text="$t('BOT_REPORTS.METRIC.TOTAL_CONVERSATIONS.TOOLTIP')"
      :value="conversationCount"
      class="flex-1"
    />
    <ReportMetricCard
      :label="$t('BOT_REPORTS.METRIC.RETAINED_OR_ASSIGNED_CONVERSATIONS_COUNT.LABEL')"
      :info-text="$t('BOT_REPORTS.METRIC.RETAINED_OR_ASSIGNED_CONVERSATIONS_COUNT.TOOLTIP')"
      :value="retainedOrAssignedConversationsCount"
      class="flex-1"
    />
    <ReportMetricCard
      :label="$t('BOT_REPORTS.METRIC.TOTAL_RESPONSES.LABEL')"
      :info-text="$t('BOT_REPORTS.METRIC.TOTAL_RESPONSES.TOOLTIP')"
      :value="messageCount"
      class="flex-1"
    />
    <ReportMetricCard
      :label="$t('BOT_REPORTS.METRIC.RETENTION_RATE.LABEL')"
      :info-text="$t('BOT_REPORTS.METRIC.RETENTION_RATE.TOOLTIP')"
      :value="formatToPercent(retentionRate)"
      class="flex-1"
    />
    <ReportMetricCard
      :label="$t('BOT_REPORTS.METRIC.HANDOFF_RATE.LABEL')"
      :info-text="$t('BOT_REPORTS.METRIC.HANDOFF_RATE.TOOLTIP')"
      :value="formatToPercent(handoffRate)"
      class="flex-1"
    />
  </div>
</template>