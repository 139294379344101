<script>
import { mapGetters } from 'vuex';
import { useAlert, useTrack } from 'dashboard/composables';
import CsatMetrics from './components/CsatMetrics.vue';
import CsatTable from './components/CsatTable.vue';
import ReportFilterSelector from './components/FilterSelector.vue';
import { generateFileName } from '../../../../helper/downloadHelper';
import { REPORTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import { FEATURE_FLAGS } from '../../../../featureFlags';
import V4Button from 'dashboard/components-next/button/Button.vue';
import ReportHeader from './components/ReportHeader.vue';

export default {
  name: 'CsatResponses',
  components: {
    CsatMetrics,
    CsatTable,
    ReportFilterSelector,
    ReportHeader,
    V4Button,
  },
  data() {
    return {
      pageIndex: 0,
      from: 0,
      to: 0,
      userIds: [],
      inbox: null,
      team: null,
      rating: null,
      classification: null,
      businessHours: false,
      initialDateRange: [],
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isFeatureEnabledOnAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    requestPayload() {
      return {
        from: this.from,
        to: this.to,
        user_ids: this.userIds.filter(id => id !== 'bot'),
        inbox_id: this.inbox,
        team_id: this.team,
        rating: this.rating,
        classification_id: this.classification,
        include_bot_responses: this.userIds.includes('bot'),
        business_hours: this.businessHours,
      };
    },
    isTeamsEnabled() {
      return this.isFeatureEnabledOnAccount(
        this.accountId,
        FEATURE_FLAGS.TEAM_MANAGEMENT
      );
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(query) {
        if (query.since || query.until || query.user_ids) {
          this.from = Number(query.since) || 0;
          this.to = Number(query.until) || 0;

          if (query.user_ids) {
            this.userIds = Array.isArray(query.user_ids)
              ? query.user_ids.map(id => Number(id))
              : [Number(query.user_ids)];
          } else {
            this.userIds = [];
          }

          if (this.from && this.to) {
            this.initialDateRange = [
              new Date(this.from * 1000),
              new Date(this.to * 1000)
            ];
          }

          if (this.from && this.to) {
            this.getAllData();
          }
        }
      },
    },
  },
  mounted() {
    if (!this.$route.query.since && !this.$route.query.until && !this.$route.query.user_ids) {
      this.getFiltersData();
    }
  },
  methods: {
    updateUrlParams() {
      const query = { ...this.$route.query };

      if (this.from) query.since = this.from;
      if (this.to) query.until = this.to;
      if (this.userIds.length) query.user_ids = this.userIds;

      this.$router.replace({ query });
    },
    getAllData() {
      try {
        this.$store.dispatch('csat/getMetrics', this.requestPayload);
        this.getResponses();
      } catch (error) {
        useAlert(this.$t('REPORT.DATA_FETCHING_FAILED'));
      }
    },
    getResponses() {
      this.$store.dispatch('csat/get', {
        page: this.pageIndex + 1,
        ...this.requestPayload,
      });
    },
    downloadReports() {
      const type = 'csat';
      try {
        this.$store.dispatch('csat/downloadCSATReports', {
          fileName: generateFileName({ type, to: this.to }),
          ...this.requestPayload,
        });
      } catch (error) {
        useAlert(this.$t('REPORT.CSAT_REPORTS.DOWNLOAD_FAILED'));
      }
    },
    onPageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getResponses();
    },
    getFiltersData() {
      this.$store.dispatch('classifications/get');
      this.$store.dispatch('agents/get');
      this.$store.dispatch('teams/get');
      this.$store.dispatch('inboxes/get');
    },
    onFilterChange({
      from,
      to,
      businessHours,
      selectedAgents,
      selectedInbox,
      selectedTeam,
      selectedRating,
      selectedClassification,
    }) {
      // Preserve URL parameters if they exist and no new values are provided
      const newFrom = from || this.from;
      const newTo = to || this.to;
      const newUserIds = selectedAgents?.length ? selectedAgents : this.userIds;

      // do not track filter change on initial load
      if (this.from !== 0 && this.to !== 0) {
        useTrack(REPORTS_EVENTS.FILTER_REPORT, {
          filterType: 'date',
          reportType: 'csat',
        });
      }

      this.from = newFrom;
      this.to = newTo;
      this.userIds = newUserIds;
      this.inbox = selectedInbox;
      this.team = selectedTeam;
      this.rating = selectedRating;
      this.classification = selectedClassification;
      this.businessHours = businessHours;

      this.updateUrlParams();
      this.getAllData();
    },
  },
};
</script>

<template>
  <ReportHeader :header-title="$t('CSAT_REPORTS.HEADER')">
    <V4Button
      :label="$t('CSAT_REPORTS.DOWNLOAD')"
      icon="i-ph-download-simple"
      size="sm"
      @click="downloadReports"
    />
  </ReportHeader>

  <div class="flex flex-col gap-4">
    <ReportFilterSelector
      type="csat"
      :initial-date-range="initialDateRange"
      @filter-change="onFilterChange"
    />
    <CsatMetrics :filters="requestPayload" />
    <CsatTable :page-index="pageIndex" @page-change="onPageNumberChange" />
  </div>
</template>
