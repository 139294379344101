<script>
import { mapGetters } from 'vuex';
import { CSAT_RATINGS } from 'shared/constants/messages';
import {
  buildFilterList,
  getActiveFilter,
  getFilterType,
} from './helpers/ReportFilterHelpers';
import FilterButton from 'dashboard/components/ui/Dropdown/DropdownButton.vue';
import ActiveFilterChip from './ActiveFilterChip.vue';
import AddFilterChip from './AddFilterChip.vue';

export default {
  props: {
    availableFilters: {
      type: Array,
      default: () => ['inboxes', 'agents', 'teams', 'labels', 'classifications', 'ratings'],
    },
    botAgent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FilterButton,
    ActiveFilterChip,
    AddFilterChip,
  },
  emits: ['filterChange'],
  data() {
    return {
      showDropdownMenu: false,
      showSubDropdownMenu: false,
      activeFilterType: '',
      appliedFilters: {
        assigned_agent_id: null,
        inbox_id: null,
        team_id: null,
        label_list: null,
        classification_id: null,
        rating: null
      },
      ratings: CSAT_RATINGS
    };
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
      teams: 'teams/getTeams',
      labels: 'labels/getLabels',
      classifications: 'classifications/getChildrenClassifications',
    }),
    agents() {
      return this.botAgent ? this.$store.getters['agents/getAgentsAndBot'] : this.$store.getters['agents/getAgents'];
    },
    filterListMenuItems() {
      const filterTypes = [
        { id: '1', name: this.$t('SLA_REPORTS.DROPDOWN.INBOXES'), type: 'inboxes' },
        { id: '2', name: this.$t('SLA_REPORTS.DROPDOWN.AGENTS'), type: 'agents' },
        { id: '3', name: this.$t('SLA_REPORTS.DROPDOWN.TEAMS'), type: 'teams' },
        { id: '4', name: this.$t('SLA_REPORTS.DROPDOWN.LABELS'), type: 'labels' },
        { id: '5', name: this.$t('SLA_REPORTS.DROPDOWN.CLASSIFICATIONS'), type: 'classifications' },
        { id: '6', name: this.$t('SLA_REPORTS.DROPDOWN.RATINGS'), type: 'ratings' },
      ].filter(({ type }) => this.availableFilters.includes(type));

      // Filter out the active filters from the filter list
      // We only want to show the filters that are not already applied
      // In the add filter dropdown
      const activeFilters = Object.keys(this.appliedFilters).filter(
        key => this.appliedFilters[key]
      );
      const activeFilterTypes = activeFilters.map(key =>
        getFilterType(key, 'keyToType')
      );

      return filterTypes
        .filter(({ type }) => !activeFilterTypes.includes(type))
        .map(({ id, name, type }) => ({
          id,
          name,
          type,
          options: buildFilterList(this[type].map(item => ({
            ...item,
            name: type === 'ratings' ? this.$t(item.translationKey) : item.name
          })), type),
        }));
    },
    activeFilters() {
      // Get the active filters from the applied filters
      // and return the filter name, type and options
      const activeKey = Object.keys(this.appliedFilters).filter(
        key => this.appliedFilters[key]
      );
      return activeKey.map(key => {
        const filterType = getFilterType(key, 'keyToType');
        const item = getActiveFilter(
          this[filterType],
          filterType,
          this.appliedFilters[key]
        );
        return {
          id: item.id,
          name: filterType === 'ratings' ? this.$t(item.translationKey) : (filterType === 'labels' ? item.title : item.name),
          type: filterType,
          options: buildFilterList(this[filterType].map(item => ({
            ...item,
            name: filterType === 'ratings' ? this.$t(item.translationKey) : item.name
          })), filterType),
        };
      });
    },
    hasActiveFilters() {
      return Object.values(this.appliedFilters).some(value => value !== null);
    },
    isAllFilterSelected() {
      return !this.filterListMenuItems.length;
    },
  },
  methods: {
    addFilter(item) {
      const { type, id } = item;
      const filterKey = getFilterType(type, 'typeToKey');
      this.appliedFilters[filterKey] = id;
      this.$emit('filterChange', this.appliedFilters);
      this.resetDropdown();
    },
    removeFilter(type) {
      const filterKey = getFilterType(type, 'typeToKey');
      this.appliedFilters[filterKey] = null;
      this.$emit('filterChange', this.appliedFilters);
    },
    clearAllFilters() {
      this.appliedFilters = {
        assigned_agent_id: null,
        inbox_id: null,
        team_id: null,
        label_list: null,
        classification_id: null,
        rating: null
      };
      this.$emit('filterChange', this.appliedFilters);
      this.resetDropdown();
    },
    showDropdown() {
      this.showSubDropdownMenu = false;
      this.showDropdownMenu = !this.showDropdownMenu;
    },
    closeDropdown() {
      this.showDropdownMenu = false;
    },
    openActiveFilterDropdown(filterType) {
      this.closeDropdown();
      this.activeFilterType = filterType;
      this.showSubDropdownMenu = !this.showSubDropdownMenu;
    },
    closeActiveFilterDropdown() {
      this.activeFilterType = '';
      this.showSubDropdownMenu = false;
    },
    resetDropdown() {
      this.closeDropdown();
      this.closeActiveFilterDropdown();
    },
  },
};
</script>

<template>
  <div
    class="flex flex-col flex-wrap items-start gap-2 md:items-center md:flex-nowrap md:flex-row"
  >
    <!-- Active filters section -->
    <div v-if="hasActiveFilters" class="flex flex-wrap gap-2 md:flex-nowrap">
      <ActiveFilterChip
        v-for="filter in activeFilters"
        v-bind="filter"
        :key="filter.type"
        :placeholder="
          $t(
            `SLA_REPORTS.DROPDOWN.INPUT_PLACEHOLDER.${filter.type.toUpperCase()}`
          )
        "
        :active-filter-type="activeFilterType"
        :show-menu="showSubDropdownMenu"
        enable-search
        @toggle-dropdown="openActiveFilterDropdown"
        @close-dropdown="closeActiveFilterDropdown"
        @add-filter="addFilter"
        @remove-filter="removeFilter"
      />
    </div>
    <!-- Dividing line between Active filters and Add filter button -->
    <div
      v-if="hasActiveFilters && !isAllFilterSelected"
      class="w-full h-px border md:w-px md:h-5 border-slate-75 dark:border-slate-800"
    />
    <!-- Add filter and clear filter button -->
    <div class="flex items-center gap-2">
      <AddFilterChip
        v-if="!isAllFilterSelected"
        placeholder-i18n-key="SLA_REPORTS.DROPDOWN.INPUT_PLACEHOLDER"
        :name="$t('SLA_REPORTS.DROPDOWN.ADD_FIlTER')"
        :menu-option="filterListMenuItems"
        :show-menu="showDropdownMenu"
        :empty-state-message="$t('SLA_REPORTS.DROPDOWN.NO_FILTER')"
        @toggle-dropdown="showDropdown"
        @close-dropdown="closeDropdown"
        @add-filter="addFilter"
      />

      <!-- Dividing line between Add filter and Clear all filter button -->
      <div
        v-if="hasActiveFilters"
        class="w-px h-5 border border-slate-75 dark:border-slate-800"
      />
      <!-- Clear all filter button -->
      <FilterButton
        v-if="hasActiveFilters"
        :button-text="$t('SLA_REPORTS.DROPDOWN.CLEAR_ALL')"
        @click="clearAllFilters"
      />
    </div>
  </div>
</template>
