<script>
import subDays from 'date-fns/subDays';
import { DATE_RANGE_OPTIONS } from '../constants';
import { getUnixStartOfDay, getUnixEndOfDay } from 'helpers/DateHelper';
import Filter from './Filters/v3/Filter.vue';

export default {
  components: {
    Filter
  },
  props: {
    showBusinessHoursSwitch: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'conversation',
    },
    initialDateRange: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['filterChange'],
  data() {
    return {
      selectedDateRange: DATE_RANGE_OPTIONS.LAST_7_DAYS,
      selectedGroupByFilter: null,
      selectedLabel: null,
      selectedInbox: null,
      selectedTeam: null,
      selectedRating: null,
      selectedClassification: null,
      selectedAgents: [],
      customDateRange: [new Date(), new Date()],
      businessHoursSelected: false,
      hasInitializedFromProps: false,
    };
  },
  computed: {
    to() {
      return getUnixEndOfDay(this.customDateRange[1]);
    },
    from() {
      return getUnixStartOfDay(this.customDateRange[0]);
    },
    validGroupBy() {
      if (!this.selectedGroupByFilter) {
        return this.validGroupOptions[0];
      }

      const validIds = this.validGroupOptions.map(opt => opt.id);
      if (validIds.includes(this.selectedGroupByFilter.id)) {
        return this.selectedGroupByFilter;
      }
      return this.validGroupOptions[0];
    },
    availableFilters() {
      const typeMapping = {
        conversation: [''],
        csat: ['agents', 'teams', 'inboxes', 'classifications', 'ratings'],
        bot: ['classifications'],
      };
      return typeMapping[this.type] || [];
    },
  },
  watch: {
    businessHoursSelected() {
      this.emitChange();
    },
    initialDateRange: {
      immediate: true,
      handler(newRange) {
        if (newRange?.length === 2 && !this.hasInitializedFromProps) {
          this.customDateRange = newRange;
          this.hasInitializedFromProps = true;
          this.emitChange();
        }
      },
    },
  },
  mounted() {
    if (!this.hasInitializedFromProps) {
      this.setInitialRange();
    }
  },
  methods: {
    emitChange() {
      this.$emit('filterChange', {
        from: this.from,
        to: this.to,
        businessHours: this.businessHoursSelected,
        selectedAgents: this.selectedAgents,
        selectedInbox: this.selectedInbox,
        selectedTeam: this.selectedTeam,
        selectedRating: this.selectedRating,
        selectedClassification: this.selectedClassification,
      });
    },
    setInitialRange() {
      const { offset } = this.selectedDateRange;
      const fromDate = subDays(new Date(), offset);
      this.customDateRange = [fromDate, new Date()];
      this.emitChange();
    },
    onDateRangeChange(value) {
      this.customDateRange = value;
      this.emitChange();
    },
    changeFilterSelection(filter) {
      this.selectedAgents = filter.assigned_agent_id ? [filter.assigned_agent_id] : [];
      this.selectedLabel = filter.label_list;
      this.selectedInbox = filter.inbox_id;
      this.selectedTeam = filter.team_id;
      this.selectedRating = filter.rating;
      this.selectedClassification = filter.classification_id;
      this.emitChange();
    },
  },
};
</script>

<template>
  <div class="flex flex-col justify-between gap-3 md:flex-row">
    <div
      class="flex flex-col flex-wrap w-full gap-3 md:flex-row"
    >
      <woot-date-picker
        :initial-date-range="initialDateRange"
        @date-range-changed="onDateRangeChange"
      />
      <Filter
        :available-filters="availableFilters"
        :bot-agent="type === 'csat'"
        @filter-change="changeFilterSelection"
      />
    </div>
    <div v-if="showBusinessHoursSwitch" class="flex items-center">
      <span class="mx-2 text-sm whitespace-nowrap">
        {{ $t('REPORT.BUSINESS_HOURS') }}
      </span>
      <span>
        <woot-switch v-model="businessHoursSelected" />
      </span>
    </div>
  </div>
</template>
