<script setup>
import { computed, h, ref, watch, nextTick, toRaw, onBeforeUnmount } from 'vue';
import {
  useVueTable,
  createColumnHelper,
  getCoreRowModel,
} from '@tanstack/vue-table';
import { useI18n } from 'vue-i18n';
import { format } from 'date-fns';

import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import Table from 'dashboard/components/table/Table.vue';
import Pagination from 'dashboard/components/table/Pagination.vue';

const { classificationMetrics, isLoading } = defineProps({
  classificationMetrics: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['download-report']);
const { t } = useI18n();

const currentPageIndex = ref(0);

const expandedRows = ref(new Set());
const expandedCustomAttributes = ref(new Set());
const showAttributesSettings = ref(false);
const selectedAttributes = ref(new Set());
const availableAttributes = ref(new Set());

watch(() => classificationMetrics, () => {
  currentPageIndex.value = 0;
}, { deep: true });

const hasAvailableAttributes = computed(() => availableAttributes.value.size > 0);

const updateAvailableAttributes = (conversations) => {
  const attributes = new Set();

  conversations.forEach(conv => {
    if (conv.custom_attributes) {
      const attrs = toRaw(conv.custom_attributes);
      Object.entries(attrs).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== '' && value !== 'N/A') {
          attributes.add(key);
        }
      });
    }
  });

  availableAttributes.value = attributes;
  if (!selectedAttributes.value) {
    selectedAttributes.value = new Set();
  }
};

const icons = {
  user: `<svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
  </svg>`,
  calendar: `<svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
  </svg>`,
  star: `<svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
  </svg>`,
  clock: `<svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>`,
  details: `<svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>`,
  settings: `<svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>`,
  check: `<svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
  </svg>`,
  download: `<svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
  </svg>`
};

const formatShortDate = dateString => {
  try {
    const date = new Date(dateString);
    return format(date, 'dd/MM HH:mm');
  } catch (e) {
    return dateString;
  }
};

const formatResolutionTime = resolutionTime => {
  if (!resolutionTime || resolutionTime <= 0) return '---';
  const hours = Math.floor(resolutionTime / 3600);
  const minutes = Math.floor((resolutionTime % 3600) / 60);
  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
};

const parentClassifications = computed(() => {
  return classificationMetrics.map(parent => ({
    ...parent,
    id: parent.id,
    isParent: true,
    isChild: false,
    isConversation: false,
    classification: parent.name,
    conversation_count: parent.conversation_count.toLocaleString(),
    resolved_count: parent.resolved_count.toLocaleString(),
    resolution_rate: `${parent.resolution_rate}%`,
    avg_resolution_time: formatResolutionTime(parent.avg_resolution_time),
    childClassifications: parent.child_classifications || [],
  }));
});

const totalCount = computed(() => parentClassifications.value.length);

const paginatedParents = computed(() => {
  const { pageIndex, pageSize } = paginationParams.value;
  const start = pageIndex * pageSize;
  const end = start + pageSize;
  return parentClassifications.value.slice(start, end);
});

const tableData = computed(() => {
  const rows = [];
  const allConversations = [];

  paginatedParents.value.forEach(parent => {
    rows.push({
      id: parent.id,
      isParent: true,
      isChild: false,
      isConversation: false,
      classification: parent.name,
      conversation_count: parent.conversation_count,
      resolved_count: parent.resolved_count,
      resolution_rate: parent.resolution_rate,
      avg_resolution_time: parent.avg_resolution_time,
      childClassifications: parent.childClassifications,
    });

    if (parent.childClassifications) {
      parent.childClassifications.forEach(child => {
        const childConversations = child.conversations || [];
        allConversations.push(...childConversations);

        rows.push({
          id: child.id,
          isParent: false,
          isChild: true,
          isConversation: false,
          classification: child.name,
          conversation_count: child.conversation_count.toLocaleString(),
          resolved_count: child.resolved_count.toLocaleString(),
          resolution_rate: `${child.resolution_rate}%`,
          avg_resolution_time: formatResolutionTime(child.avg_resolution_time),
          conversations: childConversations,
        });

        if (expandedRows.value.has(child.id)) {
          childConversations.forEach(conv => {
            rows.push({
              id: `${child.id}-${conv.id}`,
              isParent: false,
              isChild: false,
              isConversation: true,
              parentId: child.id,
              classification: conv.id,
              conversation_count: '',
              resolved_count: '',
              resolution_rate: '',
              avg_resolution_time: '',
              conversation: conv,
            });
          });
        }
      });
    }
  });

  if (allConversations.length > 0) {
    updateAvailableAttributes(allConversations);
  }

  return rows;
});

const toggleExpand = row => {
  if (row.isChild) {
    if (expandedRows.value.has(row.id)) {
      expandedRows.value.delete(row.id);
    } else {
      expandedRows.value.add(row.id);
    }
  }
};

const defaulSpanRender = cellProps => {
  const row = cellProps.row.original;
  const isClassificationCell = cellProps.column.id === 'classification';
  const isConversationCountCell = cellProps.column.id === 'conversation_count';
  const isResolvedCountCell = cellProps.column.id === 'resolved_count';
  const isResolutionRateCell = cellProps.column.id === 'resolution_rate';
  const isAvgResolutionTimeCell = cellProps.column.id === 'avg_resolution_time';

  if (row.isConversation) {
    const conv = row.conversation;

    if (isClassificationCell) {
      const url = `/app/accounts/${conv.account_id}/inbox/${conv.inbox_id}/conversations/${conv.id}`;
      return h(
        'div',
        { class: 'ml-8 flex items-center gap-2 text-sm' },
        [
          h('div', {
            class: 'text-slate-600 dark:text-slate-400',
            innerHTML: icons.calendar,
          }),
          h('span', { class: 'text-slate-600 dark:text-slate-300' }, formatShortDate(conv.created_date)),
          h(
            'a',
            {
              href: url,
              target: '_blank',
              class: 'text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300',
            },
            conv.subject || 'View conversation'
          ),
          conv.status === 'resolved' && h(
            'span',
            { class: 'text-green-600 dark:text-green-400 text-xs px-2 py-0.5 bg-green-50 dark:bg-green-900/30 rounded-full' },
            'Resolved'
          ),
        ]
      );
    }

    if (isConversationCountCell) {
      return h('div', { class: 'flex items-center gap-2 text-sm' }, [
        h('div', {
          class: 'text-slate-600 dark:text-slate-400',
          innerHTML: icons.user,
        }),
        h('span', { class: 'text-slate-800 dark:text-slate-200' }, conv.customer_name),
      ]);
    }

    if (isResolvedCountCell) {
      return null;
    }

    if (isResolutionRateCell) {
      if (conv.csat_rating === 'N/A') return null;
      return h('div', { class: 'flex items-center gap-2 text-sm' }, [
        h('div', {
          class: 'text-slate-600 dark:text-slate-400',
          innerHTML: icons.star,
        }),
        h('span', {
          class: `px-2 py-0.5 rounded text-xs ${
            Number(conv.csat_rating) >= 4
              ? 'bg-green-50 dark:bg-green-900/30 text-green-600 dark:text-green-400'
              : Number(conv.csat_rating) >= 3
              ? 'bg-yellow-50 dark:bg-yellow-900/30 text-yellow-600 dark:text-yellow-400'
              : 'bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400'
          }`
        }, conv.csat_rating),
      ]);
    }

    if (isAvgResolutionTimeCell) {
      const hasCustomAttributes = conv.custom_attributes &&
        typeof conv.custom_attributes === 'object' &&
        Object.keys(conv.custom_attributes).length > 0;

      if (!hasCustomAttributes) return null;

      const attrs = toRaw(conv.custom_attributes);
      const filteredAttrs = Object.entries(attrs)
        .filter(([key, value]) => {
          const isSelected = selectedAttributes.value.has(key);
          const isValid = value !== null && value !== undefined && value !== '' && value !== 'N/A';
          return isSelected && isValid;
        })
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      if (Object.keys(filteredAttrs).length === 0) return null;

      return h('div', { class: 'flex items-center gap-2 text-sm' }, [
        h('span', { class: 'text-slate-600 dark:text-slate-400' },
          Object.entries(filteredAttrs)
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ')
        )
      ]);
    }

    return null;
  }

  if (isClassificationCell) {
    return h(
      'div',
      {
        class: [
          'flex items-center gap-2',
          row.isChild ? 'ml-4' : 'font-medium',
        ],
      },
      [
        row.isChild && h(
          'button',
          {
            class: [
              'w-4 h-4 flex items-center justify-center text-xs border rounded',
              expandedRows.value.has(row.id)
                ? 'bg-blue-50 dark:bg-blue-900/30 border-blue-200 dark:border-blue-700'
                : 'border-slate-200 dark:border-slate-700',
            ],
            onClick: () => toggleExpand(row),
          },
          expandedRows.value.has(row.id) ? '−' : '+'
        ),
        h('span', { class: 'dark:text-slate-200' }, cellProps.getValue()),
      ]
    );
  }

  return h(
    'span',
    {
      class: [
        cellProps.getValue() ? 'dark:text-slate-200' : 'text-slate-300 dark:text-slate-700',
      ],
    },
    cellProps.getValue() ? cellProps.getValue() : '---'
  );
};

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.accessor('classification', {
    header: t('CLASSIFICATION_CONVERSATION_REPORTS.TABLE_HEADER.CLASSIFICATION'),
    cell: defaulSpanRender,
    size: 250,
  }),
  columnHelper.accessor('conversation_count', {
    header: t('CLASSIFICATION_CONVERSATION_REPORTS.TABLE_HEADER.CONVERSATION_COUNT'),
    cell: defaulSpanRender,
    size: 100,
  }),
  columnHelper.accessor('resolved_count', {
    header: t('CLASSIFICATION_CONVERSATION_REPORTS.TABLE_HEADER.RESOLVED_COUNT'),
    cell: defaulSpanRender,
    size: 100,
  }),
  columnHelper.accessor('resolution_rate', {
    header: t('CLASSIFICATION_CONVERSATION_REPORTS.TABLE_HEADER.RESOLUTION_RATE'),
    cell: defaulSpanRender,
    size: 100,
  }),
  columnHelper.accessor('avg_resolution_time', {
    header: t('CLASSIFICATION_CONVERSATION_REPORTS.TABLE_HEADER.AVG_RESOLUTION_TIME'),
    cell: defaulSpanRender,
    size: 150,
  }),
  columnHelper.accessor('actions', {
    header: '',
    cell: (cellProps) => {
      const row = cellProps.row.original;
      if (!row.isConversation) {
        return h(
          'button',
          {
            class: 'flex items-center gap-2 px-2 py-1 text-sm rounded-lg text-slate-600 hover:text-slate-800 dark:text-slate-400 dark:hover:text-slate-200',
            onClick: () => downloadClassificationReport(row.id),
            title: t('CLASSIFICATION_CONVERSATION_REPORTS.DOWNLOAD_REPORT'),
          },
          [
            h('div', {
              class: 'w-4 h-4',
              innerHTML: icons.download,
            }),
            h('span', { class: 'text-sm' }, t('CLASSIFICATION_CONVERSATION_REPORTS.DOWNLOAD_REPORT')),
          ]
        );
      }
      return null;
    },
    size: 100,
  }),
];

const paginationParams = computed(() => ({
    pageIndex: currentPageIndex.value,
    pageSize: 10,
}));

const table = useVueTable({
  get data() {
    return tableData.value;
  },
  columns,
  manualPagination: true,
  enableSorting: false,
  getCoreRowModel: getCoreRowModel(),
  get rowCount() {
    return totalCount.value;
  },
  state: {
    get pagination() {
      return paginationParams.value;
    },
  },
  onPaginationChange: updater => {
    const newPagination = updater(paginationParams.value);
    currentPageIndex.value = newPagination.pageIndex;
  },
});

const filteredCustomAttributes = (attributes) => {
  if (!attributes || typeof attributes !== 'object') {
    return {};
  }

  if (selectedAttributes.value.size === 0) {
    return {};
  }

  const attrs = toRaw(attributes);
  const filtered = Object.entries(attrs)
    .filter(([key, value]) => {
      const isSelected = selectedAttributes.value.has(key);
      const isValid = value !== null && value !== undefined && value !== '' && value !== 'N/A';
      return isSelected && isValid;
    })
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  return filtered;
};

const toggleAttribute = (attr) => {
  const newSet = new Set(selectedAttributes.value);

  if (newSet.has(attr)) {
    newSet.delete(attr);
  } else {
    newSet.add(attr);
  }

  selectedAttributes.value = newSet;

  nextTick(() => {
    table.setPageIndex(0);
  });
};

watch(expandedCustomAttributes, (newValue) => {
  nextTick(() => {
    const container = document.querySelector('.classification-conversation-table-container');
    if (!container) return;

    const existingRows = container.querySelectorAll('tr[data-details-for]');
    existingRows.forEach(row => {
      if (row && row.parentNode) {
        row.parentNode.removeChild(row);
      }
    });

    if (newValue.size > 0) {
      const expandedRowId = Array.from(newValue)[0];
      const row = tableData.value.find(r => r.id === expandedRowId);
      if (!row?.conversation?.custom_attributes) return;

      const tableElement = container.querySelector('table');
      if (!tableElement) return;

      const rows = tableElement.querySelectorAll('tr');
      const targetRow = Array.from(rows).find(r => r.innerHTML.includes(expandedRowId));
      if (!targetRow || !targetRow.parentNode) return;

      const detailsRow = document.createElement('tr');
      detailsRow.setAttribute('data-details-for', expandedRowId);
      const cell = document.createElement('td');
      cell.setAttribute('colspan', '5');
      cell.className = 'p-2 bg-slate-50 dark:bg-slate-800/50';

      const content = document.createElement('div');
      content.className = 'ml-8 text-xs';

      const filteredAttrs = filteredCustomAttributes(row.conversation.custom_attributes);
      Object.entries(filteredAttrs).forEach(([key, value]) => {
        const detail = document.createElement('div');
        detail.className = 'flex gap-2 py-0.5';
        detail.innerHTML = `
          <span class="text-slate-600 dark:text-slate-400">${key}:</span>
          <span class="text-slate-800 dark:text-slate-200">${value || 'N/A'}</span>
        `;
        content.appendChild(detail);
      });

      cell.appendChild(content);
      detailsRow.appendChild(cell);
      targetRow.parentNode.insertBefore(detailsRow, targetRow.nextSibling);
    }
  });
}, { deep: true });

onBeforeUnmount(() => {
  const container = document.querySelector('.classification-conversation-table-container');
  if (container) {
    const detailsRows = container.querySelectorAll('tr[data-details-for]');
    detailsRows.forEach(row => {
      if (row && row.parentNode) {
        row.parentNode.removeChild(row);
      }
    });
  }
});

const downloadClassificationReport = (classificationId) => {
  emit('download-report', classificationId);
};
</script>

<template>
  <div class="classification-conversation-table-container">
    <div class="flex justify-between items-center mb-4">
      <div></div>
      <button
        class="flex items-center gap-2 px-3 py-2 text-sm rounded-lg border border-slate-200 dark:border-slate-700 text-slate-600 hover:text-slate-800 dark:text-slate-400 dark:hover:text-slate-200 bg-white dark:bg-slate-800 hover:bg-slate-50 dark:hover:bg-slate-700/50"
        @click="showAttributesSettings = !showAttributesSettings"
        :title="$t('CLASSIFICATION_CONVERSATION_REPORTS.CUSTOM_ATTRIBUTES_SETTINGS')"
      >
        <div class="w-4 h-4" v-html="icons.settings" />
        <span>{{ $t('CLASSIFICATION_CONVERSATION_REPORTS.MANAGE_ATTRIBUTES') }}</span>
      </button>
    </div>

    <div class="relative">
    <Table
      :table="table"
      class="max-h-[calc(100vh-21.875rem)] border border-slate-50 dark:border-slate-800"
    />
      <Pagination
        v-if="classificationMetrics.length"
        class="mt-2"
        :table="table"
      />
    <div v-if="isLoading" class="conversation-loader">
      <Spinner />
        <span>{{ $t('CLASSIFICATION_CONVERSATION_REPORTS.LOADING_MESSAGE') }}</span>
    </div>
    <EmptyState
      v-else-if="!isLoading && !classificationMetrics.length"
      :title="$t('CLASSIFICATION_CONVERSATION_REPORTS.NO_DATA')"
    />

      <div
        v-if="showAttributesSettings"
        class="absolute top-0 right-0 w-64 bg-white dark:bg-slate-800 rounded-lg shadow-lg border border-slate-200 dark:border-slate-700 p-3 z-50"
      >
        <div class="text-sm font-medium mb-2 text-slate-700 dark:text-slate-300">
          {{ $t('CLASSIFICATION_CONVERSATION_REPORTS.CUSTOM_ATTRIBUTES') }}
        </div>
        <div v-if="isLoading" class="text-center py-4">
          {{ $t('CLASSIFICATION_CONVERSATION_REPORTS.LOADING_MESSAGE') }}
        </div>
        <div v-else-if="!hasAvailableAttributes" class="text-center py-4">
          {{ $t('CLASSIFICATION_CONVERSATION_REPORTS.NO_ATTRIBUTES') }}
        </div>
        <div v-else>
          <div class="flex flex-wrap gap-2">
            <button
              v-for="attr in Array.from(availableAttributes)"
              :key="attr"
              class="px-3 py-1 rounded-full text-sm"
              :class="{
                'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-300':
                  selectedAttributes.has(attr),
                'bg-slate-100 text-slate-700 dark:bg-slate-800 dark:text-slate-300':
                  !selectedAttributes.has(attr)
              }"
              @click="toggleAttribute(attr)"
            >
              {{ attr }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.classification-conversation-table-container {
  position: relative;
  width: 100%;
}
</style>