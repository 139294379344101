<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import ComboBox from 'dashboard/components-next/combobox/ComboBox.vue'
import Button from 'dashboard/components-next/button/Button.vue'

const props = defineProps({
  templateVariables: {
    type: Array,
    default: () => [],
  },
  csvHeaders: {
    type: Array,
    default: () => [],
  },
  variableMappings: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['update:variableMappings', 'autoMap'])

const { t } = useI18n()

// Convert CSV headers to options format for ComboBox
const headerOptions = computed(() => {
  return props.csvHeaders.map(header => ({
    value: header,
    label: header,
  }))
})

// Check if all variables are mapped to headers
const isVariableMappingComplete = computed(() => {
  if (!props.templateVariables.length) return true
  return props.templateVariables.every(
    variable => props.variableMappings[variable]
  )
})

// Set a mapping for a specific variable
const setVariableMapping = (variable, headerValue) => {
  const updatedMappings = { ...props.variableMappings }
  updatedMappings[variable] = headerValue
  emit('update:variableMappings', updatedMappings)
}

// Auto-map variables to headers based on name similarity
const autoMapVariables = () => {
  const updatedMappings = { ...props.variableMappings }
  
  props.templateVariables.forEach(variable => {
    // Skip already mapped variables
    if (updatedMappings[variable]) return
    
    // Try to find an exact match (case insensitive)
    const exactMatch = props.csvHeaders.find(
      header => header.toLowerCase() === variable.toLowerCase()
    )
    
    if (exactMatch) {
      updatedMappings[variable] = exactMatch
      return
    }
    
    // Try to find a match that contains the variable name
    const partialMatch = props.csvHeaders.find(
      header => header.toLowerCase().includes(variable.toLowerCase())
    )
    
    if (partialMatch) {
      updatedMappings[variable] = partialMatch
    }
  })
  
  emit('update:variableMappings', updatedMappings)
  emit('autoMap')
}

// Clear all mappings
const clearAllMappings = () => {
  const emptyMappings = {}
  props.templateVariables.forEach(variable => {
    emptyMappings[variable] = ''
  })
  emit('update:variableMappings', emptyMappings)
}
</script>

<template>
  <div class="variable-mapping-selector">
    <div class="header-section">
      <div>
        <h4 class="title">
          {{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.VARIABLE_MAPPING.TITLE') }}
        </h4>
        <p class="description">
          {{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.VARIABLE_MAPPING.DESCRIPTION') }}
        </p>
      </div>
      
      <div class="action-buttons">
        <Button
          size="sm"
          variant="outline"
          color="slate"
          :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.VARIABLE_MAPPING.CLEAR_ALL')"
          icon="i-lucide-x"
          @click="clearAllMappings"
        />
        <Button
          size="sm"
          variant="outline"
          color="blue"
          :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.VARIABLE_MAPPING.AUTO_MAP')"
          icon="i-lucide-wand"
          @click="autoMapVariables"
        />
      </div>
    </div>

    <div v-if="templateVariables.length === 0" class="no-variables">
      <p>{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.VARIABLE_MAPPING.NO_VARIABLES') }}</p>
    </div>

    <div v-else class="mapping-container">
      <div class="mapping-status">
        <span 
          :class="['status-indicator', isVariableMappingComplete ? 'complete' : 'incomplete']"
        >
          <span class="status-icon">
            <i v-if="isVariableMappingComplete" class="i-lucide-check-circle" />
            <i v-else class="i-lucide-alert-circle" />
          </span>
          <span class="status-text">
            {{ isVariableMappingComplete 
              ? t('CAMPAIGN.WHATSAPP.CREATE.FORM.VARIABLE_MAPPING.STATUS_COMPLETE') 
              : t('CAMPAIGN.WHATSAPP.CREATE.FORM.VARIABLE_MAPPING.STATUS_INCOMPLETE') 
            }}
          </span>
        </span>
      </div>

      <div v-for="variable in templateVariables" :key="variable" class="variable-mapping-item">
        <div class="variable-info">
          <div class="variable-label">{{ variable }}</div>
          <div class="variable-description">
            {{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.VARIABLE_MAPPING.VARIABLE_DESCRIPTION') }}
          </div>
        </div>
        
        <div class="variable-dropdown">
          <ComboBox
            :id="`variable-${variable}`"
            :value="variableMappings[variable]"
            :options="headerOptions"
            :placeholder="t('CAMPAIGN.WHATSAPP.CREATE.FORM.VARIABLE_MAPPING.SELECT_HEADER')"
            class="header-select"
            @update:model-value="headerValue => setVariableMapping(variable, headerValue)"
          />
        </div>
        
        <div 
          v-if="variableMappings[variable]" 
          class="mapping-preview"
        >
          <div class="mapping-arrow">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.VARIABLE_MAPPING.ARROW') }}</div>
          <div class="mapped-value">{{ variableMappings[variable] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.variable-mapping-selector {
  @apply flex flex-col gap-4;
}

.header-section {
  @apply flex justify-between items-start;
}

.title {
  @apply text-base font-medium text-n-slate-12;
}

.description {
  @apply text-sm text-n-slate-10 mt-1;
}

.action-buttons {
  @apply flex gap-2;
}

.no-variables {
  @apply p-4 border rounded-md bg-n-alpha-black2 text-n-slate-10;
}

.mapping-container {
  @apply flex flex-col gap-3;
}

.mapping-status {
  @apply flex justify-end mb-2;
}

.status-indicator {
  @apply flex items-center gap-1 text-xs font-medium rounded-full px-3 py-1;

  &.complete {
    background-color: #4caf50;
    color: white;
  }

  &.incomplete {
    background-color: #ffbb33;
    color: black;
  }
}

.variable-mapping-item {
  @apply flex items-center gap-4 p-3 border rounded-md bg-n-alpha-black2;
}

.variable-info {
  @apply w-1/4;
}

.variable-label {
  @apply text-sm font-semibold text-n-slate-12;
}

.variable-description {
  @apply text-xs text-n-slate-10 mt-1;
}

.variable-dropdown {
  @apply flex-1;
}

.header-select {
  @apply w-full;
}

.mapping-preview {
  @apply flex items-center gap-2 w-1/4;
}

.mapping-arrow {
  @apply text-n-slate-10;
}

.mapped-value {
  @apply px-2 py-1 bg-n-alpha-black2 rounded text-xs font-medium text-n-slate-12;
}
</style> 