<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  inboxName: {
    type: String,
    default: '',
  },
  inboxIcon: {
    type: String,
    default: '',
  },
  scheduledAt: {
    type: Number,
    default: 0,
  },
  totalContacts: {
    type: Number,
    default: 0,
  },
});

const { t } = useI18n();

const formattedDateTime = computed(() => {
  if (!props.scheduledAt) return '';
  const date = new Date(props.scheduledAt * 1000);
  return date.toLocaleString('default', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
});
</script>

<template>
  <div class="flex items-center gap-1 text-xs text-n-slate-11 truncate">
    <i :class="inboxIcon" class="text-n-slate-11" />
    <span>{{ t('CAMPAIGN.WHATSAPP.CARD.SENT_FROM') }}</span>
    <span class="font-medium text-n-slate-12">{{ inboxName }}</span>
    <span>{{ t('CAMPAIGN.WHATSAPP.CARD.ON') }}</span>
    <span class="font-medium text-n-slate-12">{{ formattedDateTime }}</span>
    <span v-if="totalContacts > 0" class="ml-2">
      <span class="text-n-slate-11">•</span>
      <span class="ml-2">
        {{ totalContacts }} {{ t('CAMPAIGN.WHATSAPP.CONTACTS') }}
      </span>
    </span>
  </div>
</template>