<script>
import { useAlert, useTrack } from 'dashboard/composables';
import BotMetrics from './components/BotMetrics.vue';
import ReportFilterSelector from './components/FilterSelector.vue';
import { GROUP_BY_FILTER } from './constants';
import ReportContainer from './ReportContainer.vue';
import { REPORTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import ReportHeader from './components/ReportHeader.vue';
import ReportsAPI from 'dashboard/api/reports';

export default {
  name: 'BotReports',
  components: {
    BotMetrics,
    ReportHeader,
    ReportFilterSelector,
    ReportContainer,
  },
  data() {
    return {
      from: 0,
      to: 0,
      groupBy: GROUP_BY_FILTER[1],
      reportKeys: {
        BOT_RESOLUTION_COUNT: 'bot_resolutions_count',
        BOT_HANDOFF_COUNT: 'bot_handoffs_count',
      },
      businessHours: false,
      conversationCount: '0',
      messageCount: '0',
      retentionRate: '0',
      handoffRate: '0',
      retainedOrAssignedConversationsCount: '0',
    };
  },
  computed: {
    requestPayload() {
      return {
        from: this.from,
        to: this.to,
        businessHours: this.businessHours,
        classification_id: this.classification
      };
    },
  },
  mounted() {
    this.getFiltersData();
  },
  methods: {
    fetchAllData() {
      this.fetchBotMetrics();
      this.fetchBotSummary();
      this.fetchChartData();
    },
    getFiltersData() {
      this.$store.dispatch('classifications/get');
    },
    fetchBotSummary() {
      try {
        this.$store.dispatch('fetchBotSummary', this.getRequestPayload());
      } catch {
        useAlert(this.$t('REPORT.SUMMARY_FETCHING_FAILED'));
      }
    },
    async fetchBotMetrics() {
      try {
        const response = await ReportsAPI.getBotMetrics(this.getRequestPayload());
        this.conversationCount = response.data.conversation_count.toLocaleString();
        this.retainedOrAssignedConversationsCount = response.data.retained_or_assigned_conversations_count.toLocaleString();
        this.messageCount = response.data.message_count.toLocaleString();
        this.retentionRate = response.data.retention_rate.toString();
        this.handoffRate = response.data.handoff_rate.toString();
      } catch {
        useAlert(this.$t('REPORT.METRICS_FETCHING_FAILED'));
      }
    },
    fetchChartData() {
      Object.keys(this.reportKeys).forEach(async key => {
        try {
          await this.$store.dispatch('fetchAccountReport', {
            metric: this.reportKeys[key],
            ...this.getRequestPayload(),
          });
        } catch {
          useAlert(this.$t('REPORT.DATA_FETCHING_FAILED'));
        }
      });
    },
    getRequestPayload() {
      const { from, to, groupBy, businessHours, classification } = this;

      return {
        from,
        to,
        groupBy: groupBy?.period,
        businessHours,
        classification_id: classification
      };
    },
    onFilterChange({
      from,
      to,
      businessHours,
      selectedClassification
    }) {
      this.from = from;
      this.to = to;
      this.classification = selectedClassification;
      this.businessHours = businessHours;
      this.fetchAllData();

      useTrack(REPORTS_EVENTS.FILTER_REPORT, {
        filterValue: { from, to },
        reportType: 'bots',
      });
    },
  },
};
</script>

<template>
  <ReportHeader :header-title="$t('BOT_REPORTS.HEADER')" />
  <div class="flex flex-col gap-4">
    <ReportFilterSelector
      :type="'bot'"
      @filter-change="onFilterChange"
    />
    <BotMetrics
      :conversation-count="conversationCount"
      :message-count="messageCount"
      :retention-rate="retentionRate"
      :handoff-rate="handoffRate"
      :retained-or-assigned-conversations-count="retainedOrAssignedConversationsCount"
    />
    <ReportContainer
      account-summary-key="getBotSummary"
      :group-by="groupBy"
      :report-keys="reportKeys"
    />
  </div>
</template>
