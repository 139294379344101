import { frontendURL } from '../../../../helper/URLHelper';

import {
  ROLES,
  CONVERSATION_PERMISSIONS,
} from 'dashboard/constants/permissions.js';

const SettingsWrapper = () => import('../SettingsWrapper.vue');
const Index = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/classifications'),
      component: SettingsWrapper,
      children: [
        {
          path: '',
          name: 'classifications_wrapper',
          meta: {
            permissions: [...ROLES, ...CONVERSATION_PERMISSIONS]
          },
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'classifications_list',
          meta: {
            permissions: [...ROLES, ...CONVERSATION_PERMISSIONS]
          },
          component: Index,
        },
      ],
    },
  ],
};
