<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'dashboard/composables/store';
import { useAlert, useTrack } from 'dashboard/composables';
import { CAMPAIGN_TYPES } from 'shared/constants/campaign.js';
import { CAMPAIGNS_EVENTS } from 'dashboard/helper/AnalyticsHelper/events.js';
import { ref } from 'vue';

import WhatsAppCampaignForm from 'dashboard/components-next/Campaigns/Pages/CampaignPage/WhatsAppCampaign/WhatsAppCampaignForm.vue';
import CampaignAudiencePreview from 'dashboard/components-next/Campaigns/Pages/CampaignPage/WhatsAppCampaign/CampaignAudiencePreview.vue';

const emit = defineEmits(['close']);

const store = useStore();
const { t } = useI18n();
const csvFile = ref(null);
const showPreview = ref(false);
const previewContacts = ref([]);
const isLoadingPreview = ref(false);
const campaignDetailsForPreview = ref(null);

async function addCampaign(campaignDetails) {
  try {
    console.log('Creating campaign with details:', campaignDetails);
    
    // If a CSV file is selected, attach it to the campaign details
    if (csvFile.value) {
      // Create a FormData object to send the file
      const formData = new FormData();
    
      // Handle variable mappings - ensure they're properly serialized
      let variableMappings = campaignDetails.variable_mappings;
      
      // If variable_mappings exists but isn't a string, stringify it
      if (variableMappings && typeof variableMappings !== 'string') {
        variableMappings = JSON.stringify(variableMappings);
      }
  
      // Add all campaign details to formData - ensure required fields are included
      Object.keys(campaignDetails).forEach(key => {
        if (key !== 'variable_mappings' && key !== 'audience' && key !== 'use_csv') {
          formData.append(`campaign[${key}]`, campaignDetails[key] === null ? '' : campaignDetails[key]);
          console.log(`Added ${key}:`, campaignDetails[key]);
        }
      });
      
      // Double-check required fields are present and not null
      // Add required fields explicitly to ensure they're included
      ['title', 'inbox_id', 'message'].forEach(field => {
        if (!formData.has(`campaign[${field}]`)) {
          const value = campaignDetails[field] || '';
          formData.append(`campaign[${field}]`, value);
          console.log(`Explicitly added ${field}:`, value);
        }
      });
      
      // Add variable_mappings separately (already serialized)
      if (variableMappings) {
        formData.append('campaign[variable_mappings]', variableMappings);
        console.log('Added variable_mappings');
      }
  
      // Add CSV file if available
      formData.append('campaign[csv_file]', csvFile.value);
      formData.append('campaign[use_csv]', 'true');
      console.log('Added CSV file and use_csv flag');
      
      // Add audience as JSON string if it exists
      if (campaignDetails.audience && campaignDetails.audience.length > 0) {
        formData.append('audience', JSON.stringify(campaignDetails.audience));
        console.log('Added audience JSON');
      }

      console.log('Submitting campaign with FormData');
      // Dispatch with FormData
      await store.dispatch('campaigns/createWithCSV', formData);
    } else {
      // Regular campaign creation without CSV
      console.log('Creating regular campaign without CSV');
      await store.dispatch('campaigns/create', {
        campaign: campaignDetails,
      });
    }

    // tracking this here instead of the store to track the type of campaign
    useTrack(CAMPAIGNS_EVENTS.CREATE_CAMPAIGN, {
      type: CAMPAIGN_TYPES.ONE_OFF,
    });

    useAlert(t('CAMPAIGN.WHATSAPP.CREATE.FORM.API.SUCCESS_MESSAGE'));
    emit('close');
  } catch (error) {
    console.error('Error creating campaign:', error);
    
    // Log error to the server or analytics instead of console
    useTrack('ERROR', {
      type: 'CAMPAIGN_CREATION_ERROR',
      message: error?.message || 'Unknown error',
    });

    const errorMessage =
      error?.response?.data?.message ||
      error?.message ||
      t('CAMPAIGN.WHATSAPP.CREATE.FORM.API.ERROR_MESSAGE');
    useAlert(errorMessage);
  }
}

const handleSubmit = campaignDetails => {
  addCampaign(campaignDetails);
};

const handleCsvFileSelected = file => {
  csvFile.value = file;
};

const handleClose = () => emit('close');

const handlePreviewAudience = async campaignDetails => {
  try {
    isLoadingPreview.value = true;
    campaignDetailsForPreview.value = campaignDetails;
    const data = {
      audience: campaignDetails.audience,
      csvFile: csvFile.value,
    };
    const response = await store.dispatch('campaigns/previewAudience', data);
    previewContacts.value = response.data.contacts;
    showPreview.value = true;
  } catch (error) {
    useAlert(t('CAMPAIGN.WHATSAPP.CREATE.FORM.API.PREVIEW_ERROR_MESSAGE'));
  } finally {
    isLoadingPreview.value = false;
  }
};

const handleBackToForm = () => {
  showPreview.value = false;
};

const handleConfirmAndCreate = () => {
  if (campaignDetailsForPreview.value) {
    addCampaign(campaignDetailsForPreview.value);
  }
};
</script>

<template>
  <div
    class="w-[450px] z-50 min-w-0 absolute top-10 ltr:right-0 rtl:left-0 bg-n-alpha-3 backdrop-blur-[100px] p-6 rounded-xl border border-slate-50 dark:border-slate-900 shadow-md flex flex-col gap-6"
    @click.stop
  >
    <h3 class="text-base font-medium text-slate-900 dark:text-slate-50">
      {{ t(`CAMPAIGN.WHATSAPP.CREATE.TITLE`) }}
    </h3>
    <WhatsAppCampaignForm
      v-if="!showPreview"
      @submit="handleSubmit"
      @preview="handlePreviewAudience"
      @cancel="handleClose"
      @csvFileSelected="handleCsvFileSelected"
      :is-loading-preview="isLoadingPreview"
    />
    <CampaignAudiencePreview
      v-else
      :contacts="previewContacts"
      @back="handleBackToForm"
      @confirm="handleConfirmAndCreate"
    />
  </div>
</template>
