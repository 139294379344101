<script>
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import {
  timeSlotParse,
  hasTimeSlotOverlap,
  generateTimeSlots
} from '../../helpers/businessHour';
import { useVuelidate } from '@vuelidate/core';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import DatePicker from 'vue-datepicker-next';
import parse from 'date-fns/parse';
import differenceInMinutes from 'date-fns/differenceInMinutes';

const timeSlots = generateTimeSlots(30);

export default {
  components: {
    MultiselectDropdown,
    DatePicker
  },
  props: {
    selectedResponse: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      date: null,
      slots: [],
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        yearFormat: 'YYYY',
        monthFormat: 'MMMM',
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'overrideDates/getUIFlags',
    }),
    pageTitle() {
      return `${this.$t('OVERRIDE_DATES_MGMT.EDIT.TITLE')} - ${
        this.title
      }`;
    },
    fromTimeSlots() {
      return timeSlots;
    },
    toTimeSlots() {
      return timeSlots.filter(slot => slot !== '12:00 AM');
    },
    isDayEnabled() {
      return this.slots.some(slot => slot.from && slot.to);
    },
    openAllDay() {
      return this.slots[0]?.openAllDay === true;
    },
    hasOverlapOrInvalid() {
      return this.slots.some((slot, index) => (!slot.valid && slot.from) || this.hasOverlap(index))
    },
  },
  mounted() {
    this.setFormValues();
  },
  watch: {
    selectedResponse: {
      immediate: true,
      handler() {
        this.setFormValues();
      }
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    isNotValid(slot) {
      return !slot.valid
    },
    hasOverlap(index) {
      const targetSlot = this.slots[index];
      return hasTimeSlotOverlap(this.slots.filter((_, i) => i !== index), targetSlot);
    },
    setFormValues() {
      this.date = new Date(this.selectedResponse.year, this.selectedResponse.month - 1, this.selectedResponse.day);
      this.slots = timeSlotParse(this.selectedResponse.working_hours)[0].slots
    },
    disabledDate(date) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return date < yesterday;
    },
    handleToTimeChange(index, value) {
      const toDate = parse(value, 'hh:mm a', new Date());
      const fromDate = parse(this.slots[index].from, 'hh:mm a', new Date());
      const valid = differenceInMinutes(toDate, fromDate) / 60 > 0;
      const updatedSlots = [...this.slots];
      updatedSlots[index] = {
        ...updatedSlots[index],
        to: value,
        valid: valid,
      };
      this.slots = updatedSlots;
    },
    handleFromTimeChange(index, value) {
      const fromDate = parse(value, 'hh:mm a', new Date());
      const toDate = parse(this.slots[index].to, 'hh:mm a', new Date());
      const valid = differenceInMinutes(toDate, fromDate) / 60 > 0;
      const updatedSlots = [...this.slots];
      updatedSlots[index] = {
        ...updatedSlots[index],
        from: value,
        valid: valid,
      };
      this.slots = updatedSlots;
    },
    addTimeSlot() {
      const newSlot = {
        from: '09:00 AM',
        to: '05:00 PM',
        valid: true,
        openAllDay: false,
      };
      this.slots.push(newSlot);
    },
    removeTimeSlot(index) {
      this.slots.splice(index, 1);
    },
    handleEnableDayChange(value) {
      this.slots = value
        ? [{
            from: '09:00 AM',
            to: '05:00 PM',
            valid: true,
            openAllDay: false,
          }]
        : [{
            from: '',
            to: '',
            valid: false,
            openAllDay: false,
          }];
    },
    handleOpenAllDayChange(value) {
      this.slots = value
        ? [{
            from: '12:00 AM',
            to: '11:59 PM',
            valid: true,
            openAllDay: true,
          }]
        : [{
            from: '09:00 AM',
            to: '05:00 PM',
            valid: true,
            openAllDay: false,
          }];
    },
    async submitForm() {
      const payload = {
        day: this.date.getDate(),
        month: this.date.getMonth() + 1,
        year: this.date.getFullYear(),
        working_hours: this.slots.map(slot => {
        const closed = slot.openAllDay ? false : !(slot.to && slot.from);
        const openAllDay = slot.openAllDay;
        let fromDate = '';
        let toDate = '';
        let openHour = '';
        let openMinutes = '';
        let closeHour = '';
        let closeMinutes = '';

        if (!closed) {
          fromDate = parse(slot.from, 'hh:mm a', new Date());
          toDate = parse(slot.to, 'hh:mm a', new Date());
          openHour = getHours(fromDate);
          openMinutes = getMinutes(fromDate);
          closeHour = getHours(toDate);
          closeMinutes = getMinutes(toDate);
        }

        return {
          closed_all_day: closed,
          open_hour: openHour,
          open_minutes: openMinutes,
          close_hour: closeHour,
          close_minutes: closeMinutes,
          open_all_day: openAllDay
        };
      }),
      };

      try {
        this.uiFlags.isUpdating = true;
      await this.$store.dispatch('overrideDates/update', {
        id: this.selectedResponse.id,
        override_date: payload
      });
        useAlert(this.$t('OVERRIDE_DATES_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        useAlert(error.message || this.$t('OVERRIDE_DATES_MGMT.EDIT.API.ERROR_MESSAGE'));
      } finally {
        this.uiFlags.isUpdating = false;
        this.$store.dispatch('inboxes/get');
      }
    },
  },
};
</script>

<template>
  <div class="flex flex-col h-auto overflow-auto">
    <woot-modal-header
      :header-title="pageTitle"
    />
    <form class="flex flex-wrap mx-0 w-full" @submit.prevent>
      <div class="flex flex-col gap-2 w-[23.75rem]">
        <label>
          {{ $t('OVERRIDE_DATES_MGMT.DATE') }}:
        </label>
        <DatePicker
          class="w-full"
          v-model:value="date"
          type="date"
          :clearable="false"
          :editable="false"
          :disabled-date="disabledDate"
          :lang="lang"
        />
      </div>

      <div class="w-full flex flex-col all-day-checkboxes">
        <div class="flex items-center gap-2 w-full">
          <input
            name="enable-day"
            class="enable-checkbox"
            type="checkbox"
            :title="$t('INBOX_MGMT.BUSINESS_HOURS.DAY.ENABLE')"
            :checked="isDayEnabled"
            @change="handleEnableDayChange($event.target.checked)"
          />
          <label>
            {{ $t('OVERRIDE_DATES_MGMT.ENABLE_DAY') }}
          </label>
        </div>
      </div>

      <div v-if="isDayEnabled" class="hours-select-wrap">
        <div class="flex items-center gap-2 w-full">
          <input
            :checked="openAllDay"
            name="enable-open-all-day"
            class="enable-checkbox"
            type="checkbox"
            :title="$t('INBOX_MGMT.BUSINESS_HOURS.ALL_DAY')"
            @change="handleOpenAllDayChange($event.target.checked)"
          />
          <label>
            {{ $t('OVERRIDE_DATES_MGMT.OPEN_ALL_DAY') }}
          </label>
        </div>
        <div
          v-for="(slot, index) in slots"
          :key="index"
          class="flex flex-col gap-2"
        >
          <div class="hours-range">
            <multiselect
              v-model="slot.from"
              :options="fromTimeSlots"
              deselect-label=""
              select-label=""
              selected-label=""
              :placeholder="$t('INBOX_MGMT.BUSINESS_HOURS.DAY.CHOOSE')"
              :allow-empty="false"
              :disabled="openAllDay"
              @input="handleFromTimeChange(index, slot.from)"
            />
            <div class="separator-icon">
              <fluent-icon icon="subtract" type="solid" size="16" />
            </div>
            <multiselect
              v-model="slot.to"
              :options="toTimeSlots"
              deselect-label=""
              select-label=""
              selected-label=""
              :placeholder="$t('INBOX_MGMT.BUSINESS_HOURS.DAY.CHOOSE')"
              :allow-empty="false"
              :disabled="openAllDay"
              @input="handleToTimeChange(index, slot.to)"
            />
            <div class="icon-buttons-wrap">
              <button
                v-if="index === 0 && !openAllDay"
                @click.prevent="addTimeSlot()"
                :title="$t('INBOX_MGMT.BUSINESS_HOURS.ADD_TIME_SLOT')"
                class="add-time-slot-button"
              >
                <fluent-icon icon="add" type="solid" size="16" />
              </button>
              <button
                v-if="slots.length > 1"
                @click.prevent="removeTimeSlot(index)"
                :title="$t('INBOX_MGMT.BUSINESS_HOURS.REMOVE_TIME_SLOT')"
                class="remove-time-slot-button"
              >
                <fluent-icon icon="delete" type="outline" size="16" />
              </button>
            </div>
          </div>
          <div v-if="isNotValid(slot)" class="date-error">
            <span class="error">{{
              $t('INBOX_MGMT.BUSINESS_HOURS.DAY.VALIDATION_ERROR')
            }}</span>
          </div>
          <div v-if="hasOverlap(index)" class="date-error">
            <span class="error">{{
              $t('INBOX_MGMT.BUSINESS_HOURS.DAY.OVERLAP_ERROR')
            }}</span>
          </div>
        </div>
      </div>
      <div v-else class="day-unavailable">
        <span>
          {{ $t('INBOX_MGMT.BUSINESS_HOURS.DAY.UNAVAILABLE') }}
        </span>
      </div>

      <div class="flex items-center justify-end w-full gap-2 px-0 py-2">
        <woot-button
          :is-loading="uiFlags.isUpdating"
          :disabled="hasOverlapOrInvalid"
          data-testid="override-date-submit"
          @click.prevent="submitForm"
        >
          {{ $t('OVERRIDE_DATES_MGMT.FORM.EDIT') }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('OVERRIDE_DATES_MGMT.FORM.CANCEL') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.multiselect {
  @apply m-0;
  > .multiselect__tags {
    @apply pl-3;

    .multiselect__single {
      @apply text-sm leading-6 py-2 px-0;
    }
  }
}

.hours-select-wrap {
  @apply flex flex-col flex-shrink-0 flex-grow gap-4 mb-2 relative;
}

.hours-range,
.day-unavailable {
  @apply flex items-center flex-shrink-0 flex-grow;
}

.separator-icon {
  @apply flex items-center py-0 px-3;
}

.multiselect {
  @apply m-0;
}

.icon-buttons-wrap {
  @apply w-[13.5rem]
}

.all-day-checkboxes {
  @apply mb-4;
}

.error {
  @apply text-xs text-red-300 dark:text-red-500;
}
</style>