<script>
import subDays from 'date-fns/subDays';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import Filter from './Filters/v3/Filter.vue';
import { DATE_RANGE_OPTIONS } from '../constants';
import { getUnixStartOfDay, getUnixEndOfDay } from 'helpers/DateHelper';

export default {
  components: {
    Thumbnail,
    Filter,
  },
  props: {
    filterItemsList: {
      type: Array,
      default: () => [],
    },
    groupByFilterItemsList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'agent',
    },
    selectedGroupByFilter: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'businessHoursToggle',
    'dateRangeChange',
    'filterChange',
    'groupByFilterChange',
  ],
  data() {
    return {
      selectedDateRange: DATE_RANGE_OPTIONS.LAST_7_DAYS,
      customDateRange: [new Date(), new Date()],
      businessHoursSelected: false,
    };
  },
  computed: {
    to() {
      return getUnixEndOfDay(this.customDateRange[1]);
    },
    from() {
      return getUnixStartOfDay(this.customDateRange[0]);
    },
    availableFilters() {
      const typeMapping = {
        agent: ['agents'],
        classification: ['classifications'],
        inbox: ['inboxes'],
        label: ['labels'],
        team: ['teams'],
      };
      return typeMapping[this.type] || [];
    },
  },
  watch: {
    businessHoursSelected() {
      this.$emit('businessHoursToggle', this.businessHoursSelected);
    },
  },
  mounted() {
    this.setInitialRange();
  },
  methods: {
    setInitialRange() {
      const { offset } = this.selectedDateRange;
      const fromDate = subDays(new Date(), offset);
      this.customDateRange = [fromDate, new Date()];
      this.emitDateRangeChange();
    },
    emitDateRangeChange() {
      this.$emit('dateRangeChange', {
        from: this.from,
        to: this.to,
      });
    },
    onDateRangeChange(value) {
      this.customDateRange = value;
      this.emitDateRangeChange();
    },
    changeFilterSelection(filter) {
      const filterKeyMapping = {
        agent: 'assigned_agent_id',
        classification: 'classification_id',
        inbox: 'inbox_id',
        label: 'label_list',
        team: 'team_id',
      };
      const filterKey = filterKeyMapping[this.type];

      filter[filterKey] !== null ? this.$emit('filterChange', { id: filter[filterKey]}) : this.$emit('filterChange', null);
    },
  },
};
</script>

<template>
  <div class="flex flex-col md:flex-row">
    <div class="flex flex-col flex-wrap w-full md:flex-row items-center">
      <woot-date-picker @date-range-changed="onDateRangeChange" />
      <Filter
        :available-filters="availableFilters"
        @filter-change="changeFilterSelection"
      />
    </div>
  </div>
</template>
