<script>
import { mapGetters } from 'vuex';
import AgentTable from './components/overview/AgentTable.vue';
import MetricCard from './components/overview/MetricCard.vue';
import InboxMetrics from './components/overview/InboxMetrics.vue';
import CsatOverviewMetrics from './components/overview/CsatOverviewMetrics.vue';
import BotOverviewMetrics from './components/overview/BotOverviewMetrics.vue';
import ConversationStatusMetrics from './components/overview/ConversationStatusMetrics.vue';
import AgentStatusMetrics from './components/overview/AgentStatusMetrics.vue';
import { OVERVIEW_METRICS } from './constants';
import ConversationTraffic from './components/overview/ConversationTraffic.vue';

import endOfDay from 'date-fns/endOfDay';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';
import { emitter } from 'shared/helpers/mitt';
import ReportHeader from './components/ReportHeader.vue';

export default {
  name: 'LiveReports',
  components: {
    ReportHeader,
    AgentTable,
    MetricCard,
    ConversationTraffic,
    InboxMetrics,
    CsatOverviewMetrics,
    BotOverviewMetrics,
    ConversationStatusMetrics,
    AgentStatusMetrics,
  },
  data() {
    return {
      // always start with 0, this is to manage the pagination in tanstack table
      // when we send the data, we do a +1 to this value
      pageIndex: 0,
      isRefreshing: false,
      lastRefreshed: null,
      autoRefreshInterval: null,
      isComponentActive: true,
      // Add a minimum refresh interval (5 minutes in milliseconds)
      minRefreshInterval: 5 * 60 * 1000,
      // Track the last time data was fetched
      lastFetchTime: 0,
    };
  },
  computed: {
    ...mapGetters({
      agentStatus: 'agents/getAgentStatus',
      agents: 'agents/getAgents',
      accountConversationMetric: 'getAccountConversationMetric',
      agentConversationMetric: 'getAgentConversationMetric',
      accountConversationHeatmap: 'getAccountConversationHeatmapData',
      uiFlags: 'getOverviewUIFlags',
      botUIFlags: 'bot/getUIFlags',
    }),
    agentStatusMetrics() {
      let metric = {};
      Object.keys(this.agentStatus).forEach(key => {
        const metricName = this.$t(
          `OVERVIEW_REPORTS.AGENT_STATUS.${OVERVIEW_METRICS[key]}`
        );
        metric[metricName] = this.agentStatus[key];
      });
      return metric;
    },
    conversationMetrics() {
      let metric = {};
      Object.keys(this.accountConversationMetric).forEach(key => {
        if (key !== 'inboxes') {
          const metricName = this.$t(
            `OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.${OVERVIEW_METRICS[key]}`
          );
          metric[metricName] = this.accountConversationMetric[key];
        }
      });
      return metric;
    },
    inboxMetrics() {
      return this.accountConversationMetric?.inboxes || [];
    },
    isLoading() {
      return (
        this.uiFlags.isFetchingAccountConversationMetric ||
        this.uiFlags.isFetchingAccountConversationsHeatmap ||
        this.uiFlags.isFetchingAgentConversationMetric ||
        this.botUIFlags?.isFetchingMetrics ||
        this.isRefreshing
      );
    },
    formattedLastRefreshed() {
      if (!this.lastRefreshed) return '';
      return new Date(this.lastRefreshed).toLocaleTimeString();
    },
  },
  mounted() {
    this.isComponentActive = true;
    this.$store.dispatch('agents/get');
    this.fetchAllData();

    // Use a single event listener with a reference we can remove later
    this.overviewReportsListener = () => {
      if (this.isComponentActive) {
        this.fetchAllData();
      }
    };
    emitter.on('fetch_overview_reports', this.overviewReportsListener);

    // Set up auto-refresh every 5 minutes
    this.autoRefreshInterval = setInterval(
      () => {
        if (this.isComponentActive && !this.isRefreshing) {
          // Only refresh if enough time has passed since the last fetch
          const now = Date.now();
          if (now - this.lastFetchTime >= this.minRefreshInterval) {
            this.refreshData();
          }
        }
      },
      5 * 60 * 1000
    );
  },
  beforeUnmount() {
    // Set component as inactive first to prevent any new API calls
    this.isComponentActive = false;
    
    // Clear the auto-refresh interval
    if (this.autoRefreshInterval) {
      clearInterval(this.autoRefreshInterval);
      this.autoRefreshInterval = null;
    }
    
    // Remove the event listener
    if (this.overviewReportsListener) {
      emitter.off('fetch_overview_reports', this.overviewReportsListener);
      this.overviewReportsListener = null;
    }
    
    // Reset all data to prevent memory leaks
    this.lastRefreshed = null;
    this.isRefreshing = false;
    this.lastFetchTime = 0;
  },
  methods: {
    fetchAllData() {
      // Don't fetch data if component is no longer active
      if (!this.isComponentActive) {
        return;
      }
      
      // Don't fetch if we've fetched recently (unless it's a manual refresh)
      const now = Date.now();
      if (now - this.lastFetchTime < this.minRefreshInterval && this.lastFetchTime > 0 && !this.isRefreshing) {
        return;
      }
      
      this.isRefreshing = true;
      this.lastFetchTime = now;
      
      // Use Promise.all to fetch data in parallel
      Promise.all([
        this.fetchAccountConversationMetric(),
        this.fetchAgentConversationMetric(),
        this.fetchHeatmapData(),
        this.fetchCsatMetrics(),
        this.fetchBotMetrics(),
      ])
        .then(() => {
          if (this.isComponentActive) {
            this.lastRefreshed = new Date();
            this.isRefreshing = false;
          }
        })
        .catch(() => {
          if (this.isComponentActive) {
            this.isRefreshing = false;
          }
        });
    },
    refreshData() {
      // Manual refresh - bypass the time check
      if (this.isLoading || !this.isComponentActive) return;
      this.isRefreshing = true;
      
      // Clear the cache first
      this.$store.dispatch('clearMetricsCache');
      
      // Then fetch all data
      this.fetchAllData();
    },
    downloadHeatmapData() {
      let to = endOfDay(new Date());

      this.$store.dispatch('downloadAccountConversationHeatmap', {
        to: getUnixTime(to),
      });
    },
    fetchHeatmapData() {
      if (this.uiFlags.isFetchingAccountConversationsHeatmap) {
        return Promise.resolve();
      }

      const to = endOfDay(new Date());
      const from = startOfDay(new Date());

      return this.$store.dispatch('fetchAccountConversationHeatmap', {
        metric: 'conversations_count',
        from: getUnixTime(from),
        to: getUnixTime(to),
        groupBy: 'hour',
        businessHours: false,
      });
    },
    fetchAccountConversationMetric() {
      return this.$store.dispatch('fetchAccountConversationMetric', {
        type: 'account',
      });
    },
    fetchAgentConversationMetric() {
      return this.$store.dispatch('fetchAgentConversationMetric', {
        type: 'agent',
        page: this.pageIndex + 1,
      });
    },
    onAgentPageNumberChange(agentPageIndex) {
      this.agentPageIndex = agentPageIndex;
      this.fetchAgentConversationMetric();
    },
    fetchCsatMetrics() {
      // Check if already fetching CSAT metrics
      if (this.uiFlags.isFetchingMetrics) {
        return Promise.resolve();
      }
      
      // Check if we've fetched recently
      const now = Date.now();
      if (now - this.lastFetchTime < this.minRefreshInterval && this.lastFetchTime > 0 && !this.isRefreshing) {
        // Return cached data
        return Promise.resolve();
      }
      
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const startTimestamp = Math.floor(today.getTime() / 1000);
      const endTimestamp = Math.floor(today.getTime() / 1000) + (24 * 60 * 60 - 1);

      return this.$store.dispatch('csat/getMetrics', {
        from: startTimestamp,
        to: endTimestamp,
      });
    },
    fetchBotMetrics() {
      // If already loading, return a resolved promise
      if (this.uiFlags.isFetchingBotMetrics) {
        return Promise.resolve();
      }

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const startTimestamp = Math.floor(today.getTime() / 1000);
      const endTimestamp = Math.floor(today.getTime() / 1000) + (24 * 60 * 60 - 1);

      return this.$store.dispatch('bot/getMetrics', {
        from: startTimestamp,
        to: endTimestamp,
      });
    },
  },
};
</script>

<template>
  <ReportHeader :header-title="$t('OVERVIEW_REPORTS.HEADER')">
    <div class="flex items-center gap-2">
      <span v-if="lastRefreshed" class="text-sm text-slate-600">
        {{ $t('OVERVIEW_REPORTS.LAST_REFRESHED') }}: {{ formattedLastRefreshed }}
      </span>
      <woot-button
        :is-loading="isRefreshing"
        icon="arrow-clockwise"
        size="small"
        variant="smooth"
        color-scheme="secondary"
        @click="refreshData"
      >
        {{ $t('OVERVIEW_REPORTS.REFRESH') }}
      </woot-button>
    </div>
  </ReportHeader>
  <div class="flex flex-col gap-4 pb-6">
    <div class="w-full">
      <MetricCard
        :header="$t('OVERVIEW_REPORTS.CONVERSATION_STATUS.HEADER')"
        :is-loading="uiFlags.isFetchingAccountConversationMetric"
        :loading-message="
          $t('OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.LOADING_MESSAGE')
        "
      >
        <ConversationStatusMetrics
          :is-loading="uiFlags.isFetchingAccountConversationMetric"
        />
      </MetricCard>
    </div>
    <div class="flex flex-row flex-wrap max-w-full">
      <MetricCard :header="$t('OVERVIEW_REPORTS.INBOX_STATUS.HEADER')">
        <InboxMetrics
          :inbox-metrics="inboxMetrics"
          :is-loading="uiFlags.isFetchingAccountConversationMetric"
        />
      </MetricCard>
    </div>
    <div class="flex flex-row flex-wrap w-full">
      <MetricCard :header="$t('BOT_REPORTS.HEADER')" class="w-full">
        <BotOverviewMetrics
          :is-loading="uiFlags.isFetchingBotMetrics"
        />
      </MetricCard>
    </div>
    <div class="flex flex-row flex-wrap w-full">
      <MetricCard :header="$t('CSAT_REPORTS.HEADER')" class="w-full">
        <CsatOverviewMetrics
          :is-loading="uiFlags.isFetchingMetrics"
        />
      </MetricCard>
    </div>
    <div class="flex flex-row flex-wrap max-w-full">
      <MetricCard :header="$t('OVERVIEW_REPORTS.CONVERSATION_HEATMAP.HEADER')">
        <template #control>
          <woot-button
            icon="arrow-download"
            size="small"
            variant="smooth"
            color-scheme="secondary"
            @click="downloadHeatmapData"
          >
            {{ $t('OVERVIEW_REPORTS.CONVERSATION_HEATMAP.DOWNLOAD_REPORT') }}
          </woot-button>
        </template>
        <ConversationTraffic
          :heat-data="accountConversationHeatmap"
          :is-loading="uiFlags.isFetchingAccountConversationsHeatmap"
        />
      </MetricCard>
    </div>
    <div class="w-full">
      <MetricCard :header="$t('OVERVIEW_REPORTS.AGENT_STATUS.HEADER')" class="w-full">
        <AgentStatusMetrics
          :is-loading="uiFlags.isFetchingAgentConversationMetric"
        />
      </MetricCard>
    </div>
    <div class="flex flex-row flex-wrap max-w-full">
      <MetricCard :header="$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.HEADER')">
        <AgentTable
          :agents="agents"
          :agent-metrics="agentConversationMetric"
          :is-loading="uiFlags.isFetchingAgentConversationMetric"
          @page-change="onAgentPageNumberChange"
        />
      </MetricCard>
    </div>
  </div>
</template>
