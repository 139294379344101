<script>
import { mapGetters } from 'vuex'
import { CSAT_RATINGS } from 'shared/constants/messages'
import BarChart from 'shared/components/charts/BarChart.vue'
import { frontendURL } from 'dashboard/helper/URLHelper'
import { useRouter } from 'vue-router'

export default {
  name: 'CsatOverviewMetrics',
  components: { BarChart },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter()
    return { router }
  },
  data() {
    return {
      csatRatings: CSAT_RATINGS,
    }
  },
  computed: {
    ...mapGetters({
      metrics: 'csat/getMetrics',
      ratingPercentage: 'csat/getRatingPercentage',
      satisfactionScore: 'csat/getSatisfactionScore',
      responseRate: 'csat/getResponseRate',
    }),
    chartData() {
      const sortedRatings = [...CSAT_RATINGS].sort((a, b) => b.value - a.value)
      return {
        labels: ['Rating'],
        datasets: sortedRatings.map(rating => ({
          label: rating.emoji,
          data: [this.ratingPercentage[rating.value]],
          backgroundColor: rating.color,
        })),
      }
    },
    responseCount() {
      return this.metrics.totalResponseCount
        ? this.metrics.totalResponseCount.toLocaleString()
        : '--'
    },
    chartOptions() {
      return {
        indexAxis: 'y',
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          x: {
            display: false,
            stacked: true,
          },
          y: {
            display: false,
            stacked: true,
          },
        },
      }
    },
  },
  methods: {
    formatToPercent(value) {
      return value ? `${value}%` : '--'
    },
    ratingToEmoji(value) {
      return CSAT_RATINGS.find(rating => rating.value === Number(value)).emoji
    },
    goToCsatReport() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      const startTimestamp = Math.floor(today.getTime() / 1000)
      const endTimestamp = Math.floor(today.getTime() / 1000) + (24 * 60 * 60 - 1)

      const url = frontendURL(`accounts/${this.router.currentRoute.value.params.accountId}/reports/csat`)
      this.router.push({
        path: url,
        query: {
          since: startTimestamp,
          until: endTimestamp,
        },
      })
    },
  },
}
</script>

<template>
  <div v-if="isLoading" class="flex items-center justify-center py-4 w-full">
    <span class="text-n-slate-11">
      {{ $t('CSAT_REPORTS.LOADING') }}
    </span>
  </div>
  <div v-else class="flex flex-col gap-4 w-full">
    <div class="flex flex-wrap gap-4 w-full">
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('CSAT_REPORTS.METRIC.TOTAL_RESPONSES.LABEL') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">{{ responseCount }}</p>
      </div>
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('CSAT_REPORTS.METRIC.SATISFACTION_SCORE.LABEL') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">
          {{ formatToPercent(satisfactionScore) }}
        </p>
      </div>
      <div class="flex-1 min-w-[200px] bg-n-slate-2 rounded-lg p-4">
        <h3 class="text-sm text-n-slate-11">
          {{ $t('CSAT_REPORTS.METRIC.RESPONSE_RATE.LABEL') }}
        </h3>
        <p class="text-2xl text-n-slate-12 mt-1">
          {{ formatToPercent(responseRate) }}
        </p>
      </div>
    </div>
    <div
      v-if="metrics.totalResponseCount"
      class="w-full flex flex-col gap-2"
    >
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center gap-4">
          <div
            v-for="(rating, key, index) in ratingPercentage"
            :key="rating + key + index"
            class="flex items-center gap-1"
          >
            <span>{{ ratingToEmoji(key) }}</span>
            <span class="text-xs text-n-slate-12">{{ formatToPercent(rating) }}</span>
          </div>
        </div>
        <button
          class="text-woot text-sm hover:underline"
          @click="goToCsatReport"
        >
          {{ $t('OVERVIEW_REPORTS.VIEW_DETAILS') }}
        </button>
      </div>
      <div class="h-6 w-full">
        <BarChart :collection="chartData" :chart-options="chartOptions" />
      </div>
    </div>
  </div>
</template>