<script setup>
import { reactive, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { useMapGetter } from 'dashboard/composables/store';
import { useAlert } from 'dashboard/composables';

import Input from 'dashboard/components-next/input/Input.vue';
import Button from 'dashboard/components-next/button/Button.vue';
import ComboBox from 'dashboard/components-next/combobox/ComboBox.vue';
import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker.vue';
import TemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParser.vue';
import TemplateVariableMappingSelector from './TemplateVariableMappingSelector.vue';

defineProps({
  isLoadingPreview: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['submit', 'csvFileSelected', 'preview']);

const { t } = useI18n();

// Form Steps
const STEPS = {
  NAME_AND_CSV: 0,
  INBOX_AND_TEMPLATE: 1,
  VARIABLE_MAPPING: 2,
  REVIEW_AND_SCHEDULE: 3,
};

const currentStep = ref(STEPS.NAME_AND_CSV);

const formState = {
  uiFlags: useMapGetter('campaigns/getUIFlags'),
  labels: useMapGetter('labels/getLabels'),
  inboxes: useMapGetter('inboxes/getWhatsAppInboxes'),
  teams: useMapGetter('teams/getTeams'),
};

const initialState = {
  title: '',
  message: '',
  inboxId: null,
  teamId: null,
  scheduledAt: null,
  selectedAudience: [],
  useTemplate: false,
  templateParams: null,
  useCSV: true, // Default to CSV mode
};

const state = reactive({ ...initialState });
const selectedWaTemplate = ref(null);
const fileInput = ref(null);
const csvFile = ref(null);
const csvFileName = ref('');
const csvHeaders = ref([]);
const templateVariables = ref([]);
const variableMappings = ref({});

// First step validation
const nameAndCsvRules = {
  title: { required, minLength: minLength(1) },
};

// Second step validation
const inboxAndTemplateRules = {
  inboxId: { required },
  teamId: { required },
};

// Fourth step validation
const reviewAndScheduleRules = {
  scheduledAt: { required },
};

// Create validators for each step
const nameAndCsvValidator = useVuelidate(nameAndCsvRules, state);
const inboxAndTemplateValidator = useVuelidate(inboxAndTemplateRules, state);
const reviewAndScheduleValidator = useVuelidate(reviewAndScheduleRules, state);

// Current validator based on step
const currentValidator = computed(() => {
  switch (currentStep.value) {
    case STEPS.NAME_AND_CSV:
      return nameAndCsvValidator.value;
    case STEPS.INBOX_AND_TEMPLATE:
      return inboxAndTemplateValidator.value;
    case STEPS.REVIEW_AND_SCHEDULE:
      return reviewAndScheduleValidator.value;
    default:
      return nameAndCsvValidator.value;
  }
});

const isCreating = computed(() => formState.uiFlags.value.isCreating);

const currentDateTime = computed(() => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
});

const mapToOptions = (items, valueKey, labelKey) =>
  items?.map(item => ({
    value: item[valueKey],
    label: item[labelKey],
  })) ?? [];

const inboxOptions = computed(() =>
  mapToOptions(formState.inboxes.value, 'id', 'name')
);

const teamOptions = computed(() =>
  mapToOptions(formState.teams.value, 'id', 'name')
);

const getErrorMessage = (field, validator, errorKey) => {
  const baseKey = 'CAMPAIGN.WHATSAPP.CREATE.FORM';
  return validator[field].$error ? t(`${baseKey}.${errorKey}.ERROR`) : '';
};

const formErrors = computed(() => ({
  title: getErrorMessage('title', nameAndCsvValidator.value, 'TITLE'),
  inbox: getErrorMessage('inboxId', inboxAndTemplateValidator.value, 'INBOX'),
  team: getErrorMessage('teamId', inboxAndTemplateValidator.value, 'TEAM'),
  scheduledAt: getErrorMessage(
    'scheduledAt',
    reviewAndScheduleValidator.value,
    'SCHEDULED_AT'
  ),
}));

// Check if all template variables have been mapped to CSV headers
const isVariableMappingComplete = computed(() => {
  if (!templateVariables.value.length) return true;
  return templateVariables.value.every(
    variable => variableMappings.value[variable]
  );
});

const isSubmitDisabled = computed(() => {
  return reviewAndScheduleValidator.value.$invalid || isCreating.value;
});

const formatToUTCString = localDateTime => {
  if (!localDateTime) return null;
  return new Date(localDateTime).toISOString();
};

const handleBack = () => {
  if (currentStep.value > 0) {
    currentStep.value -= 1;
  }
};

// Prepare campaign data for submission
const prepareCampaignData = () => {
  // First check if there are any mappings
  const hasAnyMappings = Object.keys(variableMappings.value).length > 0;
  console.log('DEBUG: Has any variable mappings:', hasAnyMappings);
  console.log('DEBUG: Variable mappings:', variableMappings.value);

  const campaignDetails = {
    title: state.title,
    message: state.message,
    inbox_id: state.inboxId,
    team_id: state.teamId,
    scheduled_at: formatToUTCString(state.scheduledAt),
    use_csv: true,
  };

  // Only include variable mappings if there are any
  if (hasAnyMappings) {
    campaignDetails.variable_mappings = variableMappings.value;
  }

  if (state.useTemplate && selectedWaTemplate.value) {
    campaignDetails.template_params = JSON.stringify({
      name: selectedWaTemplate.value.name,
      language: selectedWaTemplate.value.language,
      parameters: selectedWaTemplate.value.params,
    });
  }

  console.log('DEBUG: Final campaign details:', campaignDetails);
  return campaignDetails;
};

const parseTemplateVariables = () => {
  if (!selectedWaTemplate.value) return;
  
  // First check if there are params directly in the template
  let params = selectedWaTemplate.value.params;
  
  // If not, check if there are components with variables
  if (!params && selectedWaTemplate.value.components) {
    const bodyComponent = selectedWaTemplate.value.components.find(
      component => component.type === 'BODY'
    );
    
    if (bodyComponent && bodyComponent.text) {
      
      // Extract variables using regex pattern {{variable}}
      const variables = bodyComponent.text.match(/{{([^}]+)}}/g);
      if (variables) {
        // Create empty params object
        params = {};
        variables.forEach(variable => {
          // Extract variable name from {{variable}}
          const variableName = variable.replace(/{{|}}/g, '').trim();
          params[variableName] = '';
        });
      }
    }
  }
  
  if (!params) {
    templateVariables.value = [];
    variableMappings.value = {};
    return;
  }
  
  // Extract variables from the template params
  const extractedVariables = [];
  
  // Use Object.keys instead of for...in loop
  Object.keys(params).forEach(key => {
    // Either the value is empty or it's a placeholder that needs replacement
    if (params[key] === '' || (typeof params[key] === 'string' && params[key].includes('{{') && params[key].includes('}}'))) {
      extractedVariables.push(key);
    }
  });
  
  templateVariables.value = extractedVariables;
  
  // Initialize mappings with empty values
  variableMappings.value = extractedVariables.reduce((acc, variable) => {
    acc[variable] = '';
    return acc;
  }, {});
};

const handlePreview = () => {
  reviewAndScheduleValidator.value.$touch();
  if (reviewAndScheduleValidator.value.$invalid) return;

  const campaignDetails = prepareCampaignData();
  emit('preview', campaignDetails);
};

const handleSubmit = () => {
  reviewAndScheduleValidator.value.$touch();
  if (reviewAndScheduleValidator.value.$invalid) return;

  const campaignDetails = prepareCampaignData();
  emit('submit', campaignDetails);
};

const handleNext = () => {
  currentValidator.value.$touch();
  if (currentValidator.value.$invalid) return;
  
  // Special handling for each step
  if (currentStep.value === STEPS.NAME_AND_CSV && !csvFile.value) {
    useAlert(t('CAMPAIGN.WHATSAPP.CREATE.FORM.CSV_FILE.ERROR_REQUIRED'), {
      type: 'warning',
    });
    return;
  }
  
  if (currentStep.value === STEPS.INBOX_AND_TEMPLATE) {
    // Extract variables from template if a template is selected
    if (selectedWaTemplate.value && selectedWaTemplate.value.params) {
      parseTemplateVariables();
    }
  }

  // Move to next step
  currentStep.value += 1;
};

// Template handling methods
const toggleTemplateMode = event => {
  // Prevent form submission
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  // Only allow template mode if an inbox is selected
  if (!state.inboxId && !state.useTemplate) {
    useAlert(t('CAMPAIGN.WHATSAPP.CREATE.FORM.TEMPLATE.SELECT_INBOX_FIRST'), {
      type: 'warning',
    });
    return;
  }

  state.useTemplate = !state.useTemplate;
  if (!state.useTemplate) {
    selectedWaTemplate.value = null;
    state.templateParams = null;
  }
};

const pickTemplate = template => {
  // Log the template structure to understand what we're working with
  console.log('Template picked:', template);
  
  selectedWaTemplate.value = template;
  
  // If the template has already been parsed and sent to this component
  // from the TemplateParser, try to extract variables immediately
  if (template && (template.params || template.components)) {
    parseTemplateVariables();
  }
};

const onResetTemplate = () => {
  selectedWaTemplate.value = null;
  state.templateParams = null;
};

const onSendMessage = message => {
  console.log('Template message received:', message);
  
  state.message = message.message;
  state.templateParams = message.templateParams;
  
  // Keep the template selection visible
  state.useTemplate = true;
  
  // Create a proper structure for the template params if they are available
  if (message.templateParams) {
    // Check if template params contain the variables
    const templateInfo = message.templateParams;
    
    if (templateInfo.processed_params) {
      // Store the processed params in the template for later extraction
      if (!selectedWaTemplate.value) {
        selectedWaTemplate.value = {};
      }
      
      selectedWaTemplate.value.params = templateInfo.processed_params;
      
      // Also attempt to extract variables immediately
      parseTemplateVariables();
    }
  }
  
  // Show a success message
  useAlert(t('CAMPAIGN.WHATSAPP.CREATE.FORM.TEMPLATE.SELECTED_SUCCESS'), {
    type: 'success',
  });
};

// Add a visual indicator for selected template
const hasSelectedTemplate = computed(() => {
  return state.templateParams !== null;
});

// CSV file handling methods
const handleFileClick = () => fileInput.value?.click();

const processFileName = fileName => {
  const lastDotIndex = fileName.lastIndexOf('.');
  const extension = fileName.slice(lastDotIndex);
  const baseName = fileName.slice(0, lastDotIndex);

  return baseName.length > 20
    ? `${baseName.slice(0, 20)}...${extension}`
    : fileName;
};

const parseCSVHeaders = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      try {
        const content = event.target.result;
        const firstLine = content.split('\n')[0].trim();
        const headers = firstLine.split(',').map(header => header.trim().replace(/^"|"$/g, ''));
        resolve(headers);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsText(file);
  });
};

const handleFileChange = async () => {
  const file = fileInput.value?.files[0];
  if (file) {
    try {
      csvFile.value = file;
      csvFileName.value = processFileName(file.name);
      
      // Parse the CSV headers
      const headers = await parseCSVHeaders(file);
      csvHeaders.value = headers;
      
      emit('csvFileSelected', file);
      
      // Show success notification
      useAlert(t('CAMPAIGN.WHATSAPP.CREATE.FORM.CSV_FILE.UPLOAD_SUCCESS'), {
        type: 'success',
      });
    } catch (error) {
      useAlert(t('CAMPAIGN.WHATSAPP.CREATE.FORM.CSV_FILE.PARSE_ERROR'), {
        type: 'error',
      });
      console.error('Error parsing CSV:', error);
    }
  }
};

const handleRemoveFile = () => {
  csvFile.value = null;
  csvFileName.value = '';
  csvHeaders.value = [];
  if (fileInput.value) {
    fileInput.value.value = null;
  }
};

// Generate header options for mapping dropdown
const headerOptions = computed(() => {
  return csvHeaders.value.map(header => ({
    value: header,
    label: header,
  }));
});

const setVariableMapping = newMappings => {
  variableMappings.value = newMappings;
};
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent="handleSubmit" @click.stop>
    <!-- Step 1: Campaign Title and CSV Upload -->
    <div v-if="currentStep === STEPS.NAME_AND_CSV">
      <div class="flex items-center justify-between mb-4">
        <h3 class="text-lg font-medium">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.STEPS.NAME_AND_CSV') }}</h3>
        <div class="flex items-center gap-2">
          <div class="text-xs text-n-slate-10">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.STEP', { current: currentStep + 1, total: 4 }) }}</div>
        </div>
      </div>

      <!-- Campaign Title -->
      <Input
        v-model="state.title"
        :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.TITLE.LABEL')"
        :placeholder="t('CAMPAIGN.WHATSAPP.CREATE.FORM.TITLE.PLACEHOLDER')"
        :message="formErrors.title"
        :message-type="formErrors.title ? 'error' : 'info'"
      />

      <!-- CSV Upload -->
      <div class="flex flex-col gap-2 p-4 border border-dashed rounded-md border-n-slate-6 mt-4">
        <div class="flex items-center justify-between">
          <span class="text-sm text-n-slate-12">
            {{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.CSV_FILE.LABEL') }}
          </span>
          <div class="flex items-center gap-2">
            <span v-if="csvFile" class="text-sm text-n-slate-12">
              {{ csvFileName }}
            </span>
            <Button
              v-if="!csvFile"
              :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.CSV_FILE.CHOOSE_FILE')"
              icon="i-lucide-upload"
              color="slate"
              variant="ghost"
              size="sm"
              class="!w-fit"
              @click="handleFileClick"
            />
            <div v-else class="flex items-center gap-1">
              <Button
                :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.CSV_FILE.CHANGE')"
                color="slate"
                variant="ghost"
                size="sm"
                @click="handleFileClick"
              />
              <div class="w-px h-3 bg-n-strong" />
              <Button
                icon="i-lucide-trash"
                color="slate"
                variant="ghost"
                size="sm"
                @click="handleRemoveFile"
              />
            </div>
          </div>
        </div>
        
        <p class="text-xs text-n-slate-10">
          {{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.CSV_FILE.DESCRIPTION') }}
          <a
            href="/downloads/whatsapp-campaign-audience-sample.csv"
            target="_blank"
            rel="noopener noreferrer"
            download="whatsapp-campaign-audience-sample.csv"
            class="text-n-blue-text"
          >
            {{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.CSV_FILE.DOWNLOAD_SAMPLE') }}
          </a>
        </p>

        <!-- Display CSV Headers if available -->
        <div v-if="csvHeaders.length > 0" class="mt-2">
          <div class="text-sm font-medium text-n-slate-12 mb-1">
            {{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.CSV_FILE.DETECTED_HEADERS') }}:
          </div>
          <div class="flex flex-wrap gap-2">
            <span 
              v-for="header in csvHeaders" 
              :key="header"
              class="px-2 py-1 text-xs bg-n-alpha-black2 rounded-md text-n-slate-12"
            >
              {{ header }}
            </span>
          </div>
        </div>
      </div>

      <div class="flex justify-end mt-4">
        <Button
          variant="solid"
          color="blue"
          type="button"
          :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.BUTTONS.NEXT')"
          :disabled="nameAndCsvValidator.$invalid || !csvFile"
          @click="handleNext"
        />
      </div>
    </div>

    <!-- Step 2: Select Inbox & Template -->
    <div v-if="currentStep === STEPS.INBOX_AND_TEMPLATE">
      <div class="flex items-center justify-between mb-4">
        <h3 class="text-lg font-medium">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.STEPS.INBOX_AND_TEMPLATE') }}</h3>
        <div class="flex items-center gap-2">
          <div class="text-xs text-n-slate-10">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.STEP', { current: currentStep + 1, total: 4 }) }}</div>
        </div>
      </div>

      <!-- Select Inbox -->
      <div class="flex flex-col gap-1">
        <label for="inbox" class="mb-0.5 text-sm font-medium text-n-slate-12">
          {{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.INBOX.LABEL') }}
        </label>
        <ComboBox
          id="inbox"
          v-model="state.inboxId"
          :options="inboxOptions"
          :has-error="!!formErrors.inbox"
          :placeholder="t('CAMPAIGN.WHATSAPP.CREATE.FORM.INBOX.PLACEHOLDER')"
          :message="formErrors.inbox"
          class="[&>div>button]:bg-n-alpha-black2 [&>div>button:not(.focused)]:dark:outline-n-weak [&>div>button:not(.focused)]:hover:!outline-n-slate-6"
        />
      </div>

      <!-- Select Team -->
      <div class="flex flex-col gap-1 mt-4">
        <label for="team" class="mb-0.5 text-sm font-medium text-n-slate-12">
          {{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.TEAM.LABEL') }}
        </label>
        <ComboBox
          id="team"
          v-model="state.teamId"
          :options="teamOptions"
          :has-error="!!formErrors.team"
          :placeholder="t('CAMPAIGN.WHATSAPP.CREATE.FORM.TEAM.PLACEHOLDER')"
          :message="formErrors.team"
          class="[&>div>button]:bg-n-alpha-black2 [&>div>button:not(.focused)]:dark:outline-n-weak [&>div>button:not(.focused)]:hover:!outline-n-slate-6"
        />
      </div>

      <!-- Use Template -->
      <div class="flex flex-col gap-1 mt-4">
        <label class="mb-0.5 text-sm font-medium text-n-slate-12">
          {{ t('WHATSAPP_TEMPLATES.PICKER.USE_TEMPLATE') }}
        </label>

        <div v-if="!selectedWaTemplate && !hasSelectedTemplate" class="flex">
          <Button
            variant="solid"
            color="blue"
            type="button"
            :label="t('WHATSAPP_TEMPLATES.PICKER.SELECT_TEMPLATE')"
            class="text-sm"
            @click.stop="toggleTemplateMode"
          />
        </div>

        <div
          v-if="hasSelectedTemplate"
          class="border p-4 rounded-md bg-green-100"
        >
          <div class="flex justify-between items-center">
            <h3 class="text-sm font-medium text-green-800">
              {{ t('WHATSAPP_TEMPLATES.PICKER.TEMPLATE_SELECTED') }}
            </h3>
            <Button
              variant="faded"
              color="red"
              type="button"
              :label="t('WHATSAPP_TEMPLATES.PICKER.RESET')"
              class="text-sm"
              @click.stop="onResetTemplate"
            />
          </div>
        </div>

        <div
          v-if="(state.useTemplate || selectedWaTemplate) && !hasSelectedTemplate"
          class="border p-4 rounded-md bg-n-alpha-black2"
          @click.stop
        >
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-sm font-medium">
              {{ t('WHATSAPP_TEMPLATES.PICKER.TITLE') }}
            </h3>
            <Button
              v-if="!selectedWaTemplate"
              variant="faded"
              color="slate"
              type="button"
              :label="t('WHATSAPP_TEMPLATES.PICKER.CANCEL')"
              class="text-sm"
              @click.stop="toggleTemplateMode"
            />
          </div>

          <TemplatesPicker
            v-if="state.useTemplate && !selectedWaTemplate && state.inboxId"
            :inbox-id="state.inboxId"
            @on-select="pickTemplate"
          />
          <div
            v-else-if="state.useTemplate && !selectedWaTemplate && !state.inboxId"
            class="p-4 text-center"
          >
            <p class="text-sm text-n-slate-10">
              {{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.TEMPLATE.SELECT_INBOX_FIRST') }}
            </p>
          </div>
          <TemplateParser
            v-else-if="selectedWaTemplate"
            :template="selectedWaTemplate"
            @reset-template="onResetTemplate"
            @send-message="onSendMessage"
          />
        </div>
      </div>

      <div class="flex justify-between mt-4">
        <Button
          variant="faded"
          color="slate"
          type="button"
          :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.BUTTONS.BACK')"
          @click="handleBack"
        />
        <Button
          variant="solid"
          color="blue"
          type="button"
          :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.BUTTONS.NEXT')"
          :disabled="inboxAndTemplateValidator.$invalid || (!state.message && !selectedWaTemplate)"
          @click="handleNext"
        />
      </div>
    </div>

    <!-- Step 3: Template Variable Mapping to CSV Headers -->
    <div v-if="currentStep === STEPS.VARIABLE_MAPPING">
      <div class="flex items-center justify-between mb-4">
        <h3 class="text-lg font-medium">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.STEPS.VARIABLE_MAPPING') }}</h3>
        <div class="flex items-center gap-2">
          <div class="text-xs text-n-slate-10">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.STEP', { current: currentStep + 1, total: 4 }) }}</div>
        </div>
      </div>

      <TemplateVariableMappingSelector
        :template-variables="templateVariables"
        :csv-headers="csvHeaders"
        :variable-mappings="variableMappings"
        @update:variable-mappings="setVariableMapping"
        @auto-map="useAlert(t('CAMPAIGN.WHATSAPP.CREATE.FORM.VARIABLE_MAPPING.AUTO_MAP_SUCCESS'), { type: 'success' })"
      />

      <div class="flex justify-between mt-4">
        <Button
          variant="faded"
          color="slate"
          type="button"
          :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.BUTTONS.BACK')"
          @click="handleBack"
        />
        <Button
          variant="solid"
          color="blue"
          type="button"
          :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.BUTTONS.NEXT')"
          :disabled="!isVariableMappingComplete"
          @click="handleNext"
        />
      </div>
    </div>

    <!-- Step 4: Review & Schedule -->
    <div v-if="currentStep === STEPS.REVIEW_AND_SCHEDULE">
      <div class="flex items-center justify-between mb-4">
        <h3 class="text-lg font-medium">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.STEPS.REVIEW_AND_SCHEDULE') }}</h3>
        <div class="flex items-center gap-2">
          <div class="text-xs text-n-slate-10">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.STEP', { current: currentStep + 1, total: 4 }) }}</div>
        </div>
      </div>

      <!-- Campaign Summary -->
      <div class="p-4 border rounded-md mb-4">
        <h4 class="text-sm font-medium mb-2">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.REVIEW.SUMMARY') }}</h4>
        
        <div class="flex flex-col gap-2">
          <div class="flex justify-between text-sm">
            <span class="text-n-slate-10">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.TITLE.LABEL') }}:</span>
            <span class="font-medium">{{ state.title }}</span>
          </div>
          
          <div class="flex justify-between text-sm">
            <span class="text-n-slate-10">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.INBOX.LABEL') }}:</span>
            <span class="font-medium">{{ inboxOptions.find(option => option.value === state.inboxId)?.label }}</span>
          </div>
          
          <div class="flex justify-between text-sm">
            <span class="text-n-slate-10">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.TEAM.LABEL') }}:</span>
            <span class="font-medium">{{ teamOptions.find(option => option.value === state.teamId)?.label }}</span>
          </div>
          
          <div class="flex justify-between text-sm">
            <span class="text-n-slate-10">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.AUDIENCE.LABEL') }}:</span>
            <span class="font-medium">{{ csvFileName }}</span>
          </div>
          
          <div v-if="templateVariables.length > 0" class="flex flex-col gap-1 text-sm">
            <span class="text-n-slate-10">{{ t('CAMPAIGN.WHATSAPP.CREATE.FORM.VARIABLE_MAPPING.TITLE') }}:</span>
            <div class="ml-2">
              <div v-for="variable in templateVariables" :key="variable" class="flex justify-between">
                <span class="text-n-slate-10">{{ variable }}:</span>
                <span class="font-medium">{{ variableMappings[variable] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Schedule Settings -->
      <Input
        v-model="state.scheduledAt"
        :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.SCHEDULED_AT.LABEL')"
        type="datetime-local"
        :min="currentDateTime"
        :placeholder="t('CAMPAIGN.WHATSAPP.CREATE.FORM.SCHEDULED_AT.PLACEHOLDER')"
        :message="formErrors.scheduledAt"
        :message-type="formErrors.scheduledAt ? 'error' : 'info'"
      />

      <div class="flex justify-between mt-4">
        <Button
          variant="faded"
          color="slate"
          type="button"
          :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.BUTTONS.BACK')"
          @click="handleBack"
        />
        <div class="flex items-center gap-2">
          <Button
            variant="outline"
            color="blue"
            type="button"
            :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.BUTTONS.PREVIEW')"
            :is-loading="isLoadingPreview"
            :disabled="isCreating || isSubmitDisabled || isLoadingPreview"
            @click="handlePreview"
          />
          <Button
            :label="t('CAMPAIGN.WHATSAPP.CREATE.FORM.BUTTONS.CREATE')"
            type="submit"
            :is-loading="isCreating"
            :disabled="isCreating || isSubmitDisabled"
          />
        </div>
      </div>
    </div>

    <input
      ref="fileInput"
      type="file"
      accept="text/csv"
      class="hidden"
      @change="handleFileChange"
    />
  </form>
</template>

