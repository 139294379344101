<script>
import { mapGetters } from 'vuex';
import { debounce } from '@chatwoot/utils';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedClassification: {
      type: Object,
      default: () => ({}),
    },
  },
  
  emits: ['update:show', 'select'],

  data() {
    return {
      searchQuery: '',
      isSearching: false,
      focusedIndex: -1,
    };
  },

  computed: {
    ...mapGetters({
      activeClassifications: 'classifications/getActiveClassifications',
    }),

    filteredClassifications() {
      if (!this.searchQuery) {
        return this.activeClassifications;
      }

      const query = this.searchQuery.toLowerCase();
      return this.activeClassifications.filter(classification => 
        classification.friendly_name.toLowerCase().includes(query)
      );
    },

    noResults() {
      return this.filteredClassifications.length === 0;
    },

    localShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },

  watch: {
    searchQuery() {
      // Reset focus when search changes
      this.focusedIndex = -1;
    },
  },

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },

  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },

  methods: {
    onClose() {
      this.searchQuery = '';
      this.focusedIndex = -1;
      this.$emit('update:show', false);
    },

    handleSearch: debounce(function () {
      this.isSearching = true;
      // Simulate search delay for better UX
      setTimeout(() => {
        this.isSearching = false;
      }, 300);
    }, 300),

    onSelect(classification) {
      this.$emit('select', classification);
      this.onClose();
    },

    isSelected(classification) {
      return this.selectedClassification?.id === classification.id;
    },

    isFocused(index) {
      return this.focusedIndex === index;
    },

    handleKeyDown(event) {
      if (!this.show) return;

      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          this.focusedIndex = Math.min(
            this.focusedIndex + 1,
            this.filteredClassifications.length - 1
          );
          this.scrollToFocused();
          break;

        case 'ArrowUp':
          event.preventDefault();
          this.focusedIndex = Math.max(this.focusedIndex - 1, -1);
          this.scrollToFocused();
          break;

        case 'Enter':
          event.preventDefault();
          if (this.focusedIndex >= 0) {
            this.onSelect(this.filteredClassifications[this.focusedIndex]);
          }
          break;

        case 'Escape':
          event.preventDefault();
          this.onClose();
          break;

        default:
          break;
      }
    },

    scrollToFocused() {
      if (this.focusedIndex < 0) return;
      
      this.$nextTick(() => {
        const focusedElement = this.$refs[`item-${this.focusedIndex}`];
        if (focusedElement) {
          focusedElement.scrollIntoView({
            block: 'nearest',
            behavior: 'smooth',
          });
        }
      });
    },
  },
};
</script>

<template>
  <woot-modal v-model:show="localShow" :on-close="onClose">
    <div class="flex flex-col h-auto overflow-auto">
      <woot-modal-header
        :header-title="$t('CONVERSATION.CLASSIFICATION.TITLE')"
        :header-content="$t('CONVERSATION.CLASSIFICATION.CHANGE_CLASSIFICATION.INPUT_PLACEHOLDER')"
      />
      
      <div class="flex flex-col p-6">
        <!-- Search Input -->
        <div class="relative flex items-center mb-4">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <fluent-icon icon="search" class="w-4 h-4 text-slate-400" />
          </div>
          <input
            v-model="searchQuery"
            type="search"
            class="w-full pl-10 pr-3 py-2 border border-slate-100 dark:border-slate-700 rounded-lg bg-white dark:bg-slate-900 text-slate-700 dark:text-slate-200 focus:outline-none focus:ring-1 focus:ring-woot-500 focus:border-woot-500"
            :placeholder="$t('CONVERSATION.CLASSIFICATION.CHANGE_CLASSIFICATION.INPUT_PLACEHOLDER')"
            @input="handleSearch"
          />
        </div>

        <!-- Loading State -->
        <div v-if="isSearching" class="flex justify-center py-4">
          <spinner size="small" />
        </div>

        <!-- Results List -->
        <div v-else-if="!noResults" class="overflow-y-auto max-h-96">
          <div
            v-for="(classification, index) in filteredClassifications"
            :key="classification.id"
            :ref="`item-${index}`"
            class="flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer hover:bg-slate-50 dark:hover:bg-slate-700"
            :class="{
              'bg-slate-50 dark:bg-slate-700': isSelected(classification),
              'ring-2 ring-woot-500': isFocused(index)
            }"
            @click="onSelect(classification)"
            @mouseover="focusedIndex = index"
            @mouseleave="focusedIndex = -1"
            role="option"
            :aria-selected="isSelected(classification)"
          >
            <div class="flex items-center gap-2">
              <span class="text-sm font-medium text-slate-700 dark:text-slate-200">
                {{ classification.friendly_name }}
              </span>
            </div>
            <fluent-icon
              v-if="isSelected(classification)"
              icon="checkmark"
              class="w-4 h-4 text-woot-500"
            />
          </div>
        </div>

        <!-- No Results -->
        <div
          v-else
          class="flex flex-col items-center justify-center py-8 text-center"
        >
          <span class="text-sm text-slate-500 dark:text-slate-400">
            {{ $t('CONVERSATION.CLASSIFICATION.CHANGE_CLASSIFICATION.NO_RESULTS') }}
          </span>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<style lang="scss" scoped>
.modal-content {
  @apply pt-2 px-8 pb-8;
}
</style> 