<script setup>
import { computed, h } from 'vue';
import {
  useVueTable,
  createColumnHelper,
  getCoreRowModel,
} from '@tanstack/vue-table';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { frontendURL } from 'dashboard/helper/URLHelper';

import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import Table from 'dashboard/components/table/Table.vue';
import Pagination from 'dashboard/components/table/Pagination.vue';
import AgentCell from './AgentCell.vue';

const { agents, agentMetrics, pageIndex } = defineProps({
  agents: {
    type: Array,
    default: () => [],
  },
  agentMetrics: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  pageIndex: {
    type: Number,
    default: 1,
  },
});

const emit = defineEmits(['pageChange']);
const { t } = useI18n();
const router = useRouter();

function getAgentInformation(id) {
  return agents?.find(agent => agent.id === Number(id));
}

const totalCount = computed(() => agents.length);

// Add a new computed property to check if there are agents with metrics but none with activity today
const hasAgentsButNoActivity = computed(() => {
  // We have agents with metrics data
  if (agentMetrics.length > 0) {
    // But none of them have activity today
    const agentsWithActivity = agentMetrics.filter(
      agent => agent.metric.open > 0 || agent.closed_today > 0
    );
    return agentsWithActivity.length === 0;
  }
  return false;
});

const tableData = computed(() => {
  return agentMetrics
    .filter(agentMetric => getAgentInformation(agentMetric.id))
    // Only include agents with activity today (open conversations or resolved conversations)
    .filter(agent => agent.metric.open > 0 || agent.closed_today > 0)
    .map(agent => {
      const agentInformation = getAgentInformation(agent.id);
      return {
        agent: agentInformation.name || agentInformation.available_name,
        email: agentInformation.email,
        thumbnail: agentInformation.thumbnail,
        open: agent.metric.open,
        unattended: agent.metric.unattended,
        status: agentInformation.availability_status,
        csat: agent.csat_percentage,
        closed_today: agent.closed_today,
        resolution_time: agent.avg_resolution_time,
      };
    });
});

const formatTime = time => {
  if (!time) return '---';
  // Convert seconds to hours and minutes
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  return `${hours}h${minutes}m`;
};

const defaulSpanRender = cellProps =>
  h(
    'span',
    {
      class: cellProps.getValue() ? '' : 'text-slate-300 dark:text-slate-700',
    },
    cellProps.getValue() ? cellProps.getValue() : '---'
  );

const csatRender = cellProps => {
  const value = cellProps.getValue();
  const row = cellProps.row.original;
  const agentId = agents.find(a => a.name === row.agent)?.id;
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const startTimestamp = Math.floor(today.getTime() / 1000);
  const endTimestamp = Math.floor(today.getTime() / 1000) + (24 * 60 * 60 - 1);

  return h(
    'a',
    {
      class: value
        ? 'text-woot cursor-pointer hover:underline'
        : 'text-slate-300 dark:text-slate-700',
      onClick: () => {
        if (value) {
          const url = frontendURL(
            `accounts/${router.currentRoute.value.params.accountId}/reports/csat`
          );
          const query = {
            since: startTimestamp,
            until: endTimestamp,
            user_ids: [agentId],
          };

          router.push({
            path: url,
            query,
          });
        }
      },
    },
    value ? `${value}%` : '---'
  );
};

const timeRender = cellProps =>
  h(
    'span',
    {
      class: cellProps.getValue() ? '' : 'text-slate-300 dark:text-slate-700',
    },
    formatTime(cellProps.getValue())
  );

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.accessor('agent', {
    header: t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.AGENT'),
    cell: cellProps => h(AgentCell, cellProps),
    size: 250,
  }),
  columnHelper.accessor('open', {
    header: t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.OPEN'),
    cell: defaulSpanRender,
    size: 100,
  }),
  columnHelper.accessor('unattended', {
    header: t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.UNATTENDED'),
    cell: defaulSpanRender,
    size: 100,
  }),
  columnHelper.accessor('csat', {
    header: t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.CSAT'),
    cell: csatRender,
    size: 100,
  }),
  columnHelper.accessor('closed_today', {
    header: t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.CLOSED_TODAY'),
    cell: defaulSpanRender,
    size: 100,
  }),
  columnHelper.accessor('resolution_time', {
    header: t(
      'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.RESOLUTION_TIME'
    ),
    cell: timeRender,
    size: 100,
  }),
];

const paginationParams = computed(() => {
  return {
    pageIndex: pageIndex,
    pageSize: 25,
  };
});

const table = useVueTable({
  get data() {
    return tableData.value;
  },
  columns,
  manualPagination: true,
  enableSorting: false,
  getCoreRowModel: getCoreRowModel(),
  get rowCount() {
    return totalCount.value;
  },
  state: {
    get pagination() {
      return paginationParams.value;
    },
  },
  onPaginationChange: updater => {
    const newPagintaion = updater(paginationParams.value);
    emit('pageChange', newPagintaion.pageIndex);
  },
});
</script>

<template>
  <div class="agent-table-container">
    <Table v-if="!isLoading && tableData.length > 0" :table="table" class="max-h-[calc(100vh-21.875rem)]" />
    <Pagination v-if="!isLoading && tableData.length > 0" class="mt-2" :table="table" />
    <div v-if="isLoading" class="agents-loader">
      <Spinner />
      <span>{{
        $t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.LOADING_MESSAGE')
      }}</span>
    </div>
    <EmptyState
      v-else-if="hasAgentsButNoActivity"
      :title="$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.NO_ACTIVITY_TODAY')"
      :message="$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.NO_ACTIVITY_TODAY_DESC')"
    />
    <EmptyState
      v-else-if="!isLoading && !agentMetrics.length"
      :title="$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.NO_AGENTS')"
    />
  </div>
</template>

<style lang="scss" scoped>
.agent-table-container {
  @apply flex flex-col flex-1;

  .ve-table {
    &::v-deep {
      th.ve-table-header-th {
        @apply text-sm rounded-xl;
        padding: var(--space-small) var(--space-two) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-one) var(--space-two) !important;
      }
    }
  }

  &::v-deep .ve-pagination {
    @apply bg-transparent dark:bg-transparent;
  }

  &::v-deep .ve-pagination-select {
    @apply hidden;
  }

  .row-user-block {
    @apply items-center flex text-left;

    .user-block {
      @apply items-start flex flex-col min-w-0 my-0 mx-2;

      .title {
        @apply text-sm m-0 leading-[1.2] text-slate-800 dark:text-slate-100;
      }

      .sub-title {
        @apply text-xs text-slate-600 dark:text-slate-200;
      }
    }
  }

  .table-pagination {
    @apply mt-4 text-right;
  }
}

.agents-loader {
  @apply items-center flex text-base justify-center p-8;
}
</style>
